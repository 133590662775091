import { XIcon } from '@primer/octicons-react';
import { useEffect } from 'react';
import './index.css';

export interface AlertProps {
  title: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  onClose?: () => void;
  show: boolean;
}

const Alert = ({ title, message, type, onClose, show }: AlertProps) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onClose && onClose();
      }, 8000);
    }
  }, [show, onClose]);

  return show ? (
    <div className={`alert alert-${type}`}>
      <div className="alert-title">{title}</div>
      <div className="alert-message">{message}</div>
      <div className="alert-close" onClick={onClose}>
        <XIcon size={16} />
      </div>
    </div>
  ) : null;
};

export default Alert;
