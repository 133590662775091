import axios from 'axios';
import moment from 'moment';
import { dbService } from 'services';
import { EEnvironment } from './index.models';
import { UserRole } from 'contexts';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export class SharedService {
  getApiBase() {
    switch (this.getEnvironment()) {
      case EEnvironment.Dev:
        return process.env.REACT_APP_DEV_API_URL;
      case EEnvironment.Staging:
        return process.env.REACT_APP_STAGING_API_URL;
      case EEnvironment.Prod:
      default:
        return process.env.REACT_APP_PROD_API_URL;
    }
  }

  getEnvironment() {
    if (
      window.location.hostname === 'localhost' ||
      window.location.href.includes(EEnvironment.Dev.toLocaleLowerCase())
    ) {
      return EEnvironment.Dev;
    } else if (window.location.href.includes(EEnvironment.Staging.toLocaleLowerCase())) {
      return EEnvironment.Staging;
    }
    return EEnvironment.Prod;
  }

  createRandomPassword() {
    return Math.random().toString(36).slice(-8).toUpperCase();
  }

  async getIdToken() {
    try {
      const idToken = await dbService.auth.currentUser?.getIdToken();
      return idToken;
    } catch (error) {}
  }

  async checkUserType(email: string) {
    try {
      const res = await axios.post<{
        data: { role: UserRole };
        message: string;
      }>(
        `${this.getApiBase()}/account/check`,
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
          }
        }
      );
      return {
        role: res.data.data ? res.data.data.role : null,
        message: res.data.message
      };
    } catch (error) {
      throw error;
    }
  }

  slugify(str: string) {
    str.replace(/[^a-z0-9]/gi, '-').toLowerCase();
    str.replace(/^-+|-+$/g, '');
    str.replace(/-+/g, '-');
    str.toLocaleLowerCase();
    return str.replace(/\s+/g, '-').toLowerCase();
  }

  getTimeSince(date: moment.MomentInput) {
    const then = moment(date);
    const now = moment();
    if (now.diff(then, 'minutes') < 60) return now.diff(then, 'minutes') + 'm ago';
    if (now.diff(then, 'hours') < 24) return now.diff(then, 'hours') + 'h ago';
    return now.diff(then, 'days') + 'd ago';
  }

  getFormattedDateTime(date: moment.MomentInput) {
    return moment(date).format('DD MMM YYYY HH:mm');
  }

  getFormattedDate(date: moment.MomentInput) {
    return moment(date).format('DD MMM YYYY');
  }

  checkFeatureFlag(feature: 'newsletters' | 'students', callback: (arg0: boolean) => void) {
    const featureFlagsRef = dbService.doc(dbService.db, 'featureFlags', feature);
    const unsubscribe = dbService.onSnapshot(featureFlagsRef, doc => {
      if (doc.exists()) {
        const userId = dbService.auth.currentUser?.uid;
        const data = doc.data();
        if (data && userId) {
          const find = data.users.find((user: string) => user === userId);
          callback(find ? true : false);
        } else {
          callback(false);
        }
      }
    });
    return unsubscribe;
  }

  getQueryString(params: { [x: string]: string | number | boolean | any }) {
    return Object.keys(params)
      .filter(key => params[key] && params[key] !== 'all')
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }

  validatePhoneNumber(phone_number: string, code: CountryCode) {
    try {
      const phoneNumber = parsePhoneNumber(phone_number, code);
      return {
        error: phoneNumber.isValid() ? null : `Invalid ${code} phone number`,
        phoneNumber: phoneNumber.number
      };
    } catch (error) {
      const message = error instanceof Error ? `Invalid ${code} phone number` : 'Invalid phone number';
      return {
        error: message,
        phoneNumber: null
      };
    }
  }
}
