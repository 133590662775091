import { RankingView, Result } from 'services/results/index.model';

export enum EExamView {
  Upload = 1,
  View = 2
}

export interface IRankingStudent {
  id: string;
  admission_no: string;
  first_name: string;
  last_name: string;
  gender: 'M' | 'F';
  total_score: number;
  average_score: number;
  rank: number;
  stream_rank: number;
  results: Result[];
}

export interface IRankingPieData {
  title: string;
  labels: string[];
  pie_data: number[];
  data: {
    value: string;
    total_score: number;
    count: number;
    average_score: number;
  }[];
}

export interface IGetResultsPayload {
  school_id: string;
  class_id?: string;
  year?: string;
  term?: string;
  subject?: string;
  view?: RankingView;
  page?: number;
  size?: number;
  order_by?: string;
  direction?: 'asc' | 'desc';
}

export interface IRankingResponse {
  data: IRankingStudent[] | IRankingPieData[];
  message: string;
}

export interface IPieDataProps {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}
