import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';

import { EEnvironment } from 'services/shared/index.models';
import api from '../core';

const createConfig = (env: EEnvironment) => ({
  apiKey: process.env[`REACT_APP_${env}_API_KEY`],
  authDomain: process.env[`REACT_APP_${env}_AUTH_DOMAIN`],
  projectId: process.env[`REACT_APP_${env}_PROJECT_ID`],
  storageBucket: process.env[`REACT_APP_${env}_STORAGE_BUCKET`],
  messagingSenderId: process.env[`REACT_APP_${env}_MESSAGING_SENDER_ID`],
  appId: process.env[`REACT_APP_${env}_APP_ID`],
  measurementId: process.env[`REACT_APP_${env}_MEASUREMENT_ID`]
});

const getFirebaseConfig = () => createConfig(api.getEnvironment());

const app = initializeApp(getFirebaseConfig());
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export {
  addDoc,
  analytics,
  arrayRemove,
  arrayUnion,
  auth,
  collection,
  createUserWithEmailAndPassword,
  db,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getDownloadURL,
  increment,
  onAuthStateChanged,
  onSnapshot,
  orderBy,
  query,
  ref,
  sendPasswordResetEmail,
  serverTimestamp,
  setDoc,
  signInWithEmailAndPassword,
  storage,
  updateDoc,
  uploadBytes,
  uploadBytesResumable,
  where
};
