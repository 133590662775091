import { TriangleDownIcon, TriangleUpIcon } from '@primer/octicons-react';
import { ActionList, ActionMenu, Tooltip } from '@primer/react';
import { useEffect, useState } from 'react';
import SearchInput from '../search';
import './index.css';
import { ITable } from './index.model';

const Pagination = ({
  pages = 5,
  currentPage = 1,
  sizes = [10, 20, 25, 50, 100],
  handlePagination = (data: { page: number; size: number }) => data
}) => {
  const [pageItems, setPageItems] = useState([]) as any[];
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [selectedSize, setSelectedSize] = useState(10);

  useEffect(() => {
    const list = Array.from(Array(pages).keys()) || [];
    if (list) {
      if (pages <= 5) {
        setShowNext(false);
        setShowPrev(false);
      } else {
        setShowNext(true);
        setShowPrev(true);
      }
      if (pages < 6) {
        setPageItems(list);
      } else {
        if (currentPage <= 3) {
          setPageItems(list.slice(0, 5));
        } else if (currentPage >= pages - 2) {
          setPageItems(list.slice(pages - 5, pages));
        } else {
          setPageItems(list.slice(currentPage - 3, currentPage + 2));
        }
      }
      if (currentPage === 1) {
        setShowPrev(false);
      }
      if (currentPage === pages) {
        setShowNext(false);
      }
    }
  }, [pages, currentPage]);

  const handleNext = () => {
    if (currentPage < pages) {
      handlePagination({ page: currentPage + 1, size: selectedSize });
    } else {
      handlePagination({ page: currentPage, size: selectedSize });
    }
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePagination({ page: currentPage - 1, size: selectedSize });
    } else {
      handlePagination({ page: 1, size: selectedSize });
    }
  };
  return (
    <div className="tb-pagination">
      <button disabled={!showPrev} onClick={handlePrevious} className="tb-pagination-prev-btn pagination-btn">
        Prev
      </button>
      <div className="tb-pagination-btns">
        {pageItems.map((item: number) => (
          <button
            key={item + 1}
            className={`tb-pagination-btn-item ${item + 1 === currentPage ? 'tb-pagination-btn-item-selected' : ''}`}
            onClick={() => {
              handlePagination({
                page: item + 1,
                size: selectedSize
              });
            }}
          >
            {item + 1}
          </button>
        ))}
      </div>
      <button onClick={handleNext} disabled={!showNext} className="tb-pagination-next-btn pagination-btn">
        Next
      </button>
      <select
        className="tb-pagination-select"
        onChange={event => {
          setSelectedSize(parseInt(event.target.value));
          handlePagination({
            page: 1,
            size: parseInt(event.target.value)
          });
        }}
      >
        {sizes.map((item: number) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

const Table = ({
  className = '',
  header = [],
  data = [],
  emptyText = 'No data yet',
  itemsLabel = 'items',
  totalItems = null,
  showCount = true,
  pagination = {
    show: false,
    options: {
      sizes: [10, 20, 25, 50, 100],
      pages: 1,
      currentPage: 1,
      handlePageChange: (data: { page: number; size: number }) => data
    }
  },
  ctasConfig = {
    showSearch: false,
    showFilter: false
  },
  handleSearch = () => {},
  handleFilter = () => {},
  handleSort = () => {},
  searchConfig = {
    placeholder: 'Search...'
  },
  filterConfig = {
    placeholder: 'Filter...',
    options: [
      {
        label: 'All',
        value: 'all'
      },
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Inactive',
        value: 'inactive'
      }
    ]
  },
  onSelectAll = () => {},
  selectedCount = 0,
  selectMenuOptions = [],
  onSelectedMenuOption = () => {}
}: ITable) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemToSort, setItemToSort] = useState({
    label: '',
    direction: 'asc'
  });

  return (
    <div>
      <div style={{ alignItems: 'flex-start', justifyContent: 'space-between' }} className="flex">
        <div style={{ gap: 4 }} className="flex">
          {(ctasConfig.showSearch || ctasConfig.showFilter) && (
            <div className="table-ctas">
              {ctasConfig.showSearch && (
                <SearchInput
                  placeholder={searchConfig.placeholder}
                  onChange={event => handleSearch(event.target.value)}
                />
              )}
              {ctasConfig.showFilter && filterConfig.options && filterConfig.options.length && (
                <select
                  className="table-select"
                  onChange={event => {
                    const optGroup = event.target.options[event.target.selectedIndex].parentElement;
                    const label = optGroup ? optGroup.getAttribute('label') : null;
                    handleFilter({
                      label: label ? label.toLocaleLowerCase().replace(' ', '_') : event.target.value,
                      value: event.target.value
                    });
                  }}
                >
                  {filterConfig.options.map(item =>
                    item.children ? (
                      <optgroup label={item.label} key={item.value}>
                        {item.children.map(child => (
                          <option key={child.value.toLocaleLowerCase()} value={child.value}>
                            {child.label}
                          </option>
                        ))}
                      </optgroup>
                    ) : (
                      <option key={item.value.toLocaleLowerCase()} value={item.value}>
                        {item.label}
                      </option>
                    )
                  )}
                </select>
              )}
            </div>
          )}
          {selectedCount > 0 && selectMenuOptions.length ? (
            <div className="table-ctas">
              <div className="table-ctas-input">
                <label>Manage Selected</label>
                <ActionMenu>
                  <ActionMenu.Button>Select action</ActionMenu.Button>
                  <ActionMenu.Overlay width="medium">
                    <ActionList>
                      {selectMenuOptions.map(option => (
                        <ActionList.Item key={option.value} onSelect={() => onSelectedMenuOption(option.value)}>
                          {option.label}
                        </ActionList.Item>
                      ))}
                    </ActionList>
                  </ActionMenu.Overlay>
                </ActionMenu>
              </div>
            </div>
          ) : null}
        </div>
        {showCount ? (
          <div className="table-count">
            <span>
              {totalItems ?? data.length} {itemsLabel}
            </span>
          </div>
        ) : null}
      </div>
      {data.length === 0 ? (
        <div className="table-empty">{emptyText}</div>
      ) : (
        <table className={`table ${className}`}>
          <thead>
            <tr>
              {header.map((item, index) => (
                <th
                  style={{
                    minWidth: item.width || 'auto',
                    cursor: 'pointer',
                    userSelect: 'none'
                  }}
                  key={index + item.label}
                  onClick={() => {
                    if (item.sort) {
                      setItemToSort({
                        label: item.label,
                        direction: itemToSort.direction === 'asc' ? 'desc' : 'asc'
                      });
                      handleSort({
                        label: item.label.toLocaleLowerCase().replace(' ', '_'),
                        direction: itemToSort.direction === 'asc' ? 'desc' : 'asc'
                      });
                    }
                  }}
                >
                  {item.showSelect ? (
                    <label className="flex" style={{ gap: 3, cursor: 'pointer' }}>
                      <input
                        type="checkbox"
                        checked={selectedCount === data.length}
                        onChange={event => {
                          onSelectAll(event.target.checked);
                        }}
                      />
                      <span>{item.label}</span>
                    </label>
                  ) : (
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: '0.5rem'
                      }}
                    >
                      {item.label}
                    </span>
                  )}
                  {item.sort && (
                    <Tooltip aria-label="Click to sort" direction="e">
                      {itemToSort.label === item.label ? (
                        itemToSort.direction === 'asc' ? (
                          <TriangleUpIcon size={16} />
                        ) : (
                          <TriangleDownIcon size={16} />
                        )
                      ) : (
                        <TriangleDownIcon size={16} />
                      )}
                    </Tooltip>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{data}</tbody>
        </table>
      )}
      {pagination &&
      pagination.options &&
      pagination.options.pages &&
      totalItems &&
      pagination.options?.sizes &&
      totalItems > pagination.options?.sizes[0] ? (
        <Pagination
          pages={pagination.options.pages}
          sizes={pagination.options.sizes}
          currentPage={currentPage}
          handlePagination={data => {
            setCurrentPage(data.page);
            if (pagination && pagination.options && pagination.options.handlePageChange)
              pagination.options.handlePageChange(data);
            return data;
          }}
        />
      ) : null}
    </div>
  );
};

export default Table;
