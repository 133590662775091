import { IDateDetails } from 'pages/schools/models/index.models';

export enum ExamStatus {
  Active = 1,
  Archived = 2
}

export enum ExamAction {
  Created = 1,
  Updated = 2,
  Archived = 3,
  Deleted = 4
}

export interface IExam {
  id: string;
  code: string;
  author: string;
  name: string;
  weight?: number;
  school_id: string;
  status: ExamStatus;
  date_updated: IDateDetails;
  date_created: IDateDetails;
  total_marks?: number;
  type: ExamType;
  class: {
    id: string;
    name: string;
  };
  subject: {
    id: string;
    name: string;
  };
  history: {
    action: ExamAction;
    uid: string;
    date: IDateDetails;
  }[];
}

export interface IGetExamsRes {
  data: IExam[];
  message: string;
}

export interface IUpdateExamPayload {
  id: string;
  school_id: string;
  name: string;
  weight?: number;
  total_marks?: number;
  status?: ExamStatus;
}

export enum ExamType {
  School = 1,
  Class = 2
}

export interface IAddExamPayload {
  school_id: string;
  class_id: string;
  name: string;
  weight: number;
  subjects: string[];
}
