import axios from 'axios';
import { SharedService } from 'services/shared';

class FeesService extends SharedService {
  async exportFeesTemplate(school_id: string, query: string, callback: (arg0: string) => void) {
    const res = await axios.get(`${this.getApiBase()}/schools/${school_id}/fees/?query=${query ?? 'all'}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async uploadFees(
    {
      school_id,
      file_name
    }: {
      school_id: string;
      file_name: string;
    },
    callback: (arg0: {
      data?: {
        count: number;
      };
      message?: string;
    }) => void
  ) {
    const res = await axios.post(
      `${this.getApiBase()}/schools/${school_id}/fees/`,
      {
        file_name
      },
      {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      }
    );
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data);
  }
}

const feesService = new FeesService();

export default feesService;
