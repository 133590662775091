import { CoreContext } from 'contexts';
import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { coreService } from 'services';
import { AdminHome } from './admin';
import './index.css';
import { SchoolHome } from './school';

const Home = () => {
  const { role } = useContext(CoreContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = 'Home - Recess Admin';
    const auth = searchParams.get('auth');
    const isEmailRegex = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    if (auth && isEmailRegex.test(auth)) coreService.signOut();
  }, [searchParams]);

  return role === 'admin' ? <AdminHome /> : <SchoolHome />;
};

export default Home;
