import { UploadIcon } from '@primer/octicons-react';
import { Button, Tooltip } from '@primer/react';
import { CoreContext } from 'contexts';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { coreService, studentsService } from 'services';
import styled from 'styled-components';

const StyledOnboarding = styled.section`
  line-height: 1.5;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin: 20px 0;
  padding: 20px;
  max-width: 1250px;

  .onboarding-title {
    color: var(--green);
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .onboarding {
    &-export {
      display: flex;
      align-items: flex-start;
      flex: 1;
      gap: 20px;

      &-btns {
        margin-top: 10px;
      }
    }

    &-description {
      font-size: 0.85rem;
      color: var(--text-color);
      margin-bottom: 10px;

      p {
        margin: 0;
      }
    }
  }

  form {
    border: 1px solid var(--border-color);
    border-radius: 15px;
    padding: 15px;
    padding: 20px;
    flex: 1;
    margin: 0;
  }

  .form-group-btns {
    margin: 0;
    padding: 0;
  }

  .form-input-upload {
    &::hover {
      cursor: pointer;
      border: 1px solid var(--green);
    }
  }
`;

export const Onboarding = ({ school_id }: { school_id: string }) => {
  const { setShowAlert, setShowLineLoader, showLineLoader } = useContext(CoreContext);

  const handleBulkUpload = async (event: any) => {
    event.preventDefault();
    const { file } = event.target.elements;
    try {
      setShowLineLoader(true);
      if (file.files[0].type !== 'text/csv') {
        setShowAlert({
          show: true,
          type: 'error',
          title: 'Error',
          message: 'Please upload a csv file.'
        });
      } else {
        const uploadFile = file.files[0];
        await coreService.uploadFiles([uploadFile], school_id, 'parents', 'onboarding', async res => {
          try {
            await studentsService.uploadParents(
              {
                file_name: res.files[0].name,
                school_id: school_id ?? ''
              },
              res => {
                setShowAlert({
                  show: true,
                  type: 'success',
                  title: 'Success',
                  message: res.message
                });
                setShowLineLoader(false);
                event.target.reset();
              }
            );
          } catch (error) {
            setShowLineLoader(false);
            if (error instanceof Error) toast.error(error.message);
          }
        });
      }
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
      setShowLineLoader(false);
    }
  };

  return (
    <StyledOnboarding>
      <div className="onboarding-title">Onboarding</div>
      <form onSubmit={handleBulkUpload}>
        <div
          className="
        onboarding-description"
        >
          Before uploading a CSV file, please download the{' '}
          <u>
            <b>
              <Tooltip aria-label="Download CSV Template" direction="s">
                <a
                  href="https://admin.recess.school/docs/recess-parents-template.csv"
                  download="recess-parents-template"
                >
                  csv template
                </a>
              </Tooltip>
            </b>
          </u>{' '}
          to bulk upload parents use <b>email addresses</b> or <b>phone numbers starting with 254</b> to allow them to{' '}
          <b>
            <Tooltip aria-label="Parents Onboarding" direction="s">
              <a href="https://onboarding.recess.school" target="_blank" rel="noopener noreferrer">
                self onboard
              </a>
            </Tooltip>
          </b>{' '}
          their children
        </div>
        <div className="form-group">
          <label htmlFor="file" className="form-label">
            Select CSV File
          </label>
          <input type="file" name="file" id="file" className="form-input form-input-upload" accept=".csv" required />
        </div>
        <div className="form-group-btns">
          <Button disabled={showLineLoader} type="submit">
            <UploadIcon size={16} />
            <span style={{ marginLeft: 5 }}>{showLineLoader ? 'Processing' : 'Upload'}</span>
          </Button>
        </div>
      </form>
    </StyledOnboarding>
  );
};
