import { SmileyIcon, SponsorTiersIcon, TrackedByClosedCompletedIcon, TrophyIcon } from '@primer/octicons-react';
import { NavList, NavListItem } from 'components/nav';
import { useBadges } from 'hooks';
import { IDateDetails } from 'pages/schools/models/index.models';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

export enum EBadgeType {
  Academic = 1,
  Behavioural = 2,
  Attendance = 3,
  Cocurricular = 4
}

export interface IStudentBadge {
  school_id: string;
  year: number;
  author: string;
  class_id: string;
  name: string;
  student_id: string;
  remark: string;
  term: number;
  admission_no: string;
  type: EBadgeType;
  date_updated: IDateDetails;
  date_created: IDateDetails;
  id: string;
}

const createBadges = (size: number) => [
  {
    name: 'Academic',
    type: EBadgeType.Academic,
    icon: <SponsorTiersIcon size={size} />
  },
  {
    name: 'Behavioural',
    type: EBadgeType.Behavioural,
    icon: <SmileyIcon size={size} />
  },
  {
    name: 'Attendance',
    type: EBadgeType.Attendance,
    icon: <TrackedByClosedCompletedIcon size={size} />
  },
  {
    name: 'Cocurricular',
    type: EBadgeType.Cocurricular,
    icon: <TrophyIcon size={size} />
  }
];

const BADGES = createBadges(24);
const BADGES_LARGE = createBadges(32);

const StyledSection = styled.section`
  padding: 20px 0;

  p {
    font-size: 14px;
    font-weight: 400;
    color: #586069;
    padding: 0;
    margin: 0;
  }

  .content {
    padding: 0;
    margin-top: 20px;
    display: flex;

    height: 100%;
    gap: 20px;

    .left {
      flex-basis: 250px;
    }

    .right {
      flex: 1;
    }

    .right-container {
      box-shadow: var(--box-shadow);
      background-color: var(--white);
      padding: 15px 20px;
      border-radius: 15px;
      width: 100%;
    }

    

    .badges {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      flex 1;
     
    }
  }
`;

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const StyledSkelelton = styled.div`
  text-decoration: none;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background: rgba(234, 238, 242, 0.7);
  animation: ${pulse} 2s ease-in-out infinite; /* add pulse animation */
`;

const StyledBadge = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    border: 1px solid #00bcd4;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background: linear-gradient(135deg, #00bcd4, #88d8b0); /* cyan gradient background */

    &:hover {
      border: 1px solid #00bcd4;
      transition: all 0.2s ease-in-out;
      background-color: #f6f8fa;
      rotate: 360deg;
    }
  }

  .badge-name {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #00bcd4;
  }

  .badge-date {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #586069;
  }
`;

export const BadgesSection = ({
  student
}: {
  student: {
    student_id: string;
    class_id: string;
  };
}) => {
  const { badges, loading } = useBadges(student);
  const [data, setData] = useState(badges.academic.badges);
  const [activeTab, setActiveTab] = useState<EBadgeType>(EBadgeType.Academic);

  useEffect(() => {
    setData(badges.academic.badges);
  }, [badges]);

  const renderSkeletons = () => (
    <div className="badges">
      {[1, 2, 3, 4].map(index => (
        <StyledSkelelton key={index} />
      ))}
    </div>
  );

  const renderBadges = () => (
    <div className="badges">
      {data.map(badge => (
        <StyledBadge key={badge.id}>
          <div className="badge">{BADGES_LARGE.find(b => b.type === badge.type)?.icon}</div>
          <div className="badge-name">{badge.name}</div>
          <div className="badge-date">{badge.date_created.short_date}</div>
        </StyledBadge>
      ))}
    </div>
  );

  const renderNoBadgesMessage = () => <p>No badges yet in this category</p>;

  return (
    <StyledSection>
      <div className="badges-title">All badges earned by the student in each category</div>
      <section className="content">
        <div className="left">
          <NavList>
            {BADGES.map(badge => (
              <NavListItem
                onClick={() => {
                  setActiveTab(badge.type);
                  setData(badges[badge.name.toLowerCase() as keyof typeof badges]?.badges ?? []);
                }}
                key={badge.type}
                active={activeTab === badge.type ? 'true' : 'false'}
              >
                {badge.icon}
                {badge.name} ({badges[badge.name.toLowerCase() as keyof typeof badges].count})
              </NavListItem>
            ))}
          </NavList>
        </div>
        <div className="right">
          <div className="right-container">
            {loading ? renderSkeletons() : data.length > 0 ? renderBadges() : renderNoBadgesMessage()}
          </div>
        </div>
      </section>
    </StyledSection>
  );
};
