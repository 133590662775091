import styled from 'styled-components';

const StyledParents = styled.div`
  // Add your styles here
`;

export const Parents = ({
  data = []
}: {
  data: Array<{
    id: string;
    name: string;
    email: string;
  }>;
}) => {
  return (
    <StyledParents>
      {data.length > 0 ? (
        data.map(parent => (
          <div key={parent.id}>
            <div>{parent.name}</div>
            <div>{parent.email}</div>
          </div>
        ))
      ) : (
        <div>No parents yet</div>
      )}
    </StyledParents>
  );
};
