import { Rings } from 'react-loader-spinner';
import './index.css';

const LineLoader = ({ show = true, size = '2px', color = '#00bcd4', speed = 1, hideShadow = false }) => {
  return show ? (
    <section
      style={{
        backgroundColor: hideShadow ? 'transparent' : 'rgba(0, 0, 0, 0.5)'
      }}
      className="loader-overlay"
    >
      <div className="loader-line">
        <div className="loader-line-container"></div>
      </div>
    </section>
  ) : null;
};

const PageLoader = ({ show = true }) => {
  return (
    <section style={show ? { display: 'flex' } : {}} className="loader-page">
      <Rings color="#ddd" ariaLabel="loading-indicator" />
    </section>
  );
};

export { LineLoader, PageLoader };
