import { CommandPaletteIcon } from '@primer/octicons-react';
import { CounterLabel, Label } from '@primer/react';
import { ENewsLetterStatus, ENewsLetterStatusLabel, INewsLetter } from 'services/newsletters/index.models';
import DOMPurify from 'dompurify';
import './index.css';

export const NewsLetterCard = ({ data, onClick }: { data: INewsLetter; onClick?: any }) => {
  return (
    <div className="newsletter-card">
      <div onClick={onClick} className="newsletter-card-item">
        <div className="newsletter-card-left">
          <div className="newsletter-card-icon-box">
            <CommandPaletteIcon size={16} className="newsletter-card-icon" />
          </div>
          <div className="newsletter-card-body">
            <div className="newsletter-card-title">{data.title}</div>
            <div
              className="newsletter-card-content"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.content)
              }}
            ></div>
          </div>
        </div>
        <div className="newsletter-card-right">
          <Label
            variant={
              data.status === ENewsLetterStatus.Draft
                ? 'accent'
                : data.status === ENewsLetterStatus.Active
                ? 'success'
                : data.status === ENewsLetterStatus.All
                ? 'sponsors'
                : 'default'
            }
            className="newsletter-card-status"
          >
            {data.status === ENewsLetterStatus.Active
              ? ENewsLetterStatusLabel.Active
              : data.status === ENewsLetterStatus.Draft
              ? ENewsLetterStatusLabel.Draft
              : data.status === ENewsLetterStatus.All
              ? ENewsLetterStatusLabel.All
              : data.status === ENewsLetterStatus.Archived
              ? ENewsLetterStatusLabel.Archived
              : ENewsLetterStatusLabel.Draft}
          </Label>
          <div className="newsletter-card-date">{data.date_created.time_since}</div>
        </div>
      </div>
      <div className="newsletter-card-footer">
        <span className="newsletter-card-footer-item">
          <span className="newsletter-card-footer-item-value">
            <CounterLabel>{data.attachments.length}</CounterLabel>
          </span>
          <span className="newsletter-card-footer-item-label">Attachments</span>
        </span>
        <span className="newsletter-card-footer-item">
          <span className="newsletter-card-footer-views">
            {data.status === ENewsLetterStatus.Active && (
              <span className="newsletter-card-footer-item-label">
                <CounterLabel>{data.views.length}</CounterLabel> Reads
              </span>
            )}
          </span>
        </span>
      </div>
    </div>
  );
};
