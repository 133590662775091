import { ChevronDownIcon, ChevronUpIcon } from '@primer/octicons-react';
import { IconButton, Token } from '@primer/react';
import { useState } from 'react';
import styled from 'styled-components';

const StyledExamAccordion = styled.div`
  width: 100%;
  .exams-accordion {
    border: 1px solid var(--gray-300);
    border-radius: 8px;
    overflow: hidden;

    &-header {
      padding: 10px 12px;
      background-color: var(--gray-100);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &-name {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    &-content {
      padding: 20px;
      border-top: 1px solid var(--gray-300);
      display: none;
    }

    &-content.active {
      display: block;
    }
  }
`;

export const ExamAccordion = ({
  data,
  content
}: {
  data: {
    id: string;
    label: string;
    name: string;
  };
  content: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);

  return (
    <StyledExamAccordion>
      <div className="exams-accordion">
        <div className="exams-accordion-header" onClick={() => setActive(!active)}>
          <div className="exams-accordion-header-name">
            <span>{data.name}</span> <Token text={data.label} />
          </div>
          <IconButton aria-labelledby="icon" icon={active ? ChevronUpIcon : ChevronDownIcon} />
        </div>
        {content && <div className={`exams-accordion-content ${active ? 'active' : ''}`}>{content}</div>}
      </div>
    </StyledExamAccordion>
  );
};
