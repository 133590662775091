import { Button, CounterLabel, Flash, TabNav, Token } from '@primer/react';
import { TabNavSecondary } from 'components';
import { CoreContext } from 'contexts';
import { Layout } from 'layouts';
import { chain, groupBy, map } from 'lodash';
import { IClass } from 'pages/schools/models/index.models';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { coreService } from 'services';
import { examService } from 'services/exams';
import { EManageType } from '../models/index.models';
import { Account, ExamList, Users } from './components';
import { ExamAccordion } from './components/exam-group';
import { ExamData } from './index.models';
import { ADMIN_TABS, SCHOOL_TABS } from '../iindex.constants';
import styled from 'styled-components';

const StyledManage = styled.section`
  .manage-content {
    margin: 0 auto;
    padding: 20px;
    width: 100%;
  }

  .welcome {
    color: var(--hover-color);
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 10px;
  }

  .welcome small {
    color: var(--text-color);
    text-decoration: underline;
    font-size: 0.9rem;
  }

  .manage-user {
    padding: 0;
  }

  .manage-user-blurb {
    font-size: 14px;
  }

  .btn-primary {
    min-width: 120px;
  }

  .manage-user-ctas {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .manage-user-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .manage-type {
    padding: 20px 0;
  }

  .manage-type-item {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    border-radius: 15px;
    padding: 15px;
    flex: 1;
    width: 100%;
  }

  .manage-type,
  .manage-type-item-content,
  .manage-type-item-title,
  .manage-type-item-name {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .manage-type {
    flex-direction: column;
    align-items: flex-start;
  }

  .manage-type-item-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .manage-type-item-title {
    color: var(--green);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .manage-type-item-subtitle {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .manage-type-item-content {
    font-size: 14px;
  }

  .manage-type-item-name span:first-child {
    font-weight: 500;
  }

  .manage-create-form {
    margin-bottom: 10px;
    max-width: 750px;
    width: 100%;
  }

  .manage-type-item-exam-list {
    width: 100%;
  }

  .type-item-exam-box-info {
    padding: 8px 10px !important;
    font-size: 13px;
  }
`;

export const ManageHome = () => {
  const classRef = useRef<HTMLSelectElement>(null);
  const { showLineLoader, setShowLineLoader, user, role, examList, setExamList, profile } = useContext(CoreContext);

  const [activeTab, setActiveTab] = useState(EManageType.Account);
  const [tabList, setTabList] = useState<{ label: string; id: EManageType }[]>([]);
  const [examView, setExamView] = useState<'add-exam' | 'view-exams'>('add-exam');
  const [classes, setClasses] = useState<IClass[]>([]);
  const [selectedClass, setSelectedClass] = useState<IClass | null>(null);
  const [subjects, setSubjects] = useState<{ id: string; name: string }[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<{ id: string; name: string }[]>([]);
  const [examData, setExamData] = useState<ExamData[]>([]);

  useEffect(() => {
    document.title = `Manage - ${profile?.name ?? 'School'}`;
    setTabList(role === 'admin' ? ADMIN_TABS : SCHOOL_TABS);
  }, [role, profile]);

  useEffect(() => {
    if (examList) {
      setExamData(
        chain(examList)
          .groupBy('class.name')
          .map((value, key) => ({
            name: key,
            id: key.split(' ').join('-').toLowerCase(),
            data: map(groupBy(value, 'name'), (exams, examName) => ({
              id: `${key}-${examName}`.split(' ').join('-').toLowerCase(),
              name: examName,
              exams
            }))
          }))
          .value()
      );
    }
  }, [examList]);

  useEffect(() => {
    if (selectedClass) setSubjects(selectedClass.subjects);
  }, [selectedClass]);

  useEffect(() => {
    (async () => {
      try {
        if (user && role === 'school') {
          setShowLineLoader(true);
          const res = await coreService.getSchoolById(user.uid);
          setClasses(res.classes ?? []);
          setShowLineLoader(false);
        }
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setShowLineLoader(false);
      }
    })();
  }, [user, setShowLineLoader, role]);

  return (
    <Layout
      back={{
        label: 'Back to home',
        link: '/'
      }}
      className="manage"
    >
      <StyledManage>
        <section className="manage-content">
          <section className="manage-user">
            <div className="manage-user-title">School Profile</div>
            <TabNav className="news-header-tabs" aria-label="Main">
              {tabList.map(tab => (
                <TabNav.Link
                  onClick={() => {
                    setActiveTab(tab.id);
                  }}
                  key={tab.id}
                  selected={activeTab === tab.id}
                  className={activeTab === tab.id ? 'tab-active' : ''}
                  sx={{
                    color: activeTab === tab.id ? '#1cbc96' : '#000',
                    fontWeight: activeTab === tab.id ? 'bold' : 'normal',
                    cursor: 'pointer'
                  }}
                >
                  {tab.label}
                </TabNav.Link>
              ))}
            </TabNav>
            {activeTab === EManageType.Account && <Account />}
            {role === 'school' && activeTab === EManageType.Exams && (
              <div className="manage-type">
                <div className="manage-type-item">
                  <div className="manage-type-item-title">
                    <CounterLabel>01</CounterLabel>
                    <span>Add Exams</span>
                  </div>

                  <div className="manage-type-item-content">
                    <TabNavSecondary
                      data={[
                        {
                          label: 'Add Exam',
                          value: 'add-exam'
                        },
                        {
                          label: 'View Exams',
                          value: 'view-exams'
                        }
                      ]}
                      onSelect={value => setExamView(value as 'add-exam' | 'view-exams')}
                    />
                    {examView === 'add-exam' ? (
                      <form
                        className="manage-create-form"
                        onSubmit={async (event: any) => {
                          event.preventDefault();
                          try {
                            const { name, weight } = event.target;
                            if (user) {
                              setShowLineLoader(true);
                              await examService.createExam(
                                {
                                  name: name.value,
                                  weight: Number(weight.value),
                                  school_id: user.uid,
                                  subjects: selectedSubjects.map(item => item.id),
                                  class_id: selectedClass?.id ?? ''
                                },
                                data => {
                                  toast.success(data.message);
                                  setSelectedSubjects([]);
                                  event.target.reset();
                                }
                              );
                              await examService.getExams(user.uid, data => {
                                setExamList(data);
                                setShowLineLoader(false);
                              });
                            }
                          } catch (error) {
                            if (error instanceof Error) toast.error(error.message);
                            setShowLineLoader(false);
                          }
                        }}
                      >
                        <div className="manage-type-item-subtitle">
                          Add name of exams that will be taken by students in your school
                        </div>
                        <div className="form-group-flex">
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">
                              Exam Name
                            </label>
                            <input
                              name="name"
                              id="name"
                              className="form-input"
                              placeholder="For e.g CAT 1, Midterm, Endterm, etc."
                              type="text"
                              required={true}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="weight" className="form-label">
                              Exam Weight (in %)
                            </label>
                            <input
                              name="weight"
                              id="weight"
                              type="number"
                              max={100}
                              min={1}
                              className="form-input"
                              placeholder="For e.g 30, 40, 50, etc."
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="class" className="form-label">
                            Select a class
                          </label>
                          <select
                            name="class"
                            id="class"
                            className="form-input"
                            required={true}
                            onChange={event => {
                              event.preventDefault();
                              const value = event.target.value;
                              if (value === 'select') setSelectedClass(null);
                              const selected = classes.find(item => item.id === value);
                              if (selected) setSelectedClass(selected);
                            }}
                          >
                            <option value="select">Select</option>
                            {classes.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {selectedClass && (
                          <div className="form-group">
                            <label htmlFor="subjects" className="form-label">
                              Select a subject
                            </label>
                            {selectedSubjects.length ? (
                              <div
                                style={{
                                  marginTop: 0
                                }}
                                className="chips"
                              >
                                {selectedSubjects.map(item => (
                                  <Token
                                    size="large"
                                    id={item.id}
                                    key={item.id}
                                    text={item.name}
                                    onRemove={() => {
                                      const newLIst = selectedSubjects.filter(classItem => classItem.id !== item.id);
                                      setSelectedSubjects(newLIst);
                                    }}
                                  />
                                ))}
                              </div>
                            ) : null}
                            <select
                              ref={classRef}
                              name="subjects"
                              id="subjects"
                              className="form-input"
                              required={true}
                              value={
                                selectedSubjects.length && selectedSubjects.length === subjects.length
                                  ? 'all'
                                  : selectedSubjects.length
                                  ? selectedSubjects[0]?.id
                                  : 'select'
                              }
                              onChange={event => {
                                event.preventDefault();
                                const value = event.target.value;
                                if (value === 'select') setSelectedSubjects([]);
                                if (value === 'all') setSelectedSubjects(subjects);
                                if (selectedSubjects.find(item => item.id === value)) return;
                                const selected = subjects.find(item => item.id === value);
                                if (selected) setSelectedSubjects(prev => [...prev, selected]);
                              }}
                            >
                              {[
                                {
                                  id: 'select',
                                  name: 'Select'
                                },
                                {
                                  id: 'all',
                                  name: 'All'
                                },
                                ...subjects
                              ].map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        <Button disabled={showLineLoader} type="submit">
                          {showLineLoader ? 'Please Wait' : 'Add Exam'}
                        </Button>
                      </form>
                    ) : (
                      <div className="manage-type-item-exam-list">
                        <div className="manage-type-item-subtitle">List of exams taken by students in your school</div>
                        {examData.length ? (
                          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            {examData.map(item => (
                              <ExamAccordion
                                key={item.id}
                                content={
                                  <div style={{ flexDirection: 'column', gap: 15 }} className="flex">
                                    {item.data.map((exam, index) => (
                                      <ExamAccordion
                                        key={exam.id + index}
                                        content={<ExamList data={exam.exams} />}
                                        data={{
                                          id: exam.id,
                                          name: exam.name,
                                          label: `${exam.exams.length} subjects`
                                        }}
                                      />
                                    ))}
                                  </div>
                                }
                                data={{
                                  id: item.id,
                                  name: item.name,
                                  label: `${item.data.length} exams`
                                }}
                              />
                            ))}
                          </div>
                        ) : showLineLoader ? (
                          <Flash className="type-item-exam-box-info">Please wait as we fetch exam list...</Flash>
                        ) : (
                          <Flash className="type-item-exam-box-info">
                            Sorry, no exams added yet. Make sure to add some
                          </Flash>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {role === 'school' && activeTab === EManageType.Curriculum && (
              <div className="manage-type">
                <div className="manage-type-item">
                  <div className="manage-type-item-title">
                    <CounterLabel>01</CounterLabel>
                    <span>Setup</span>
                  </div>
                  <div className="manage-type-item-subtitle">Add or update curriculum for your school</div>
                  <div className="manage-type-item-content">
                    <div>
                      This feature is <b>Coming soon...</b>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {role === 'admin' && activeTab === EManageType.Users && (
              <div className="manage-type">
                <div className="manage-type-item">
                  <div className="manage-type-item-title">
                    <CounterLabel>01</CounterLabel>
                    <span>Users</span>
                  </div>
                  <div className="manage-type-item-subtitle">Manage users in your school</div>
                  <div className="manage-type-item-content">
                    <div>
                      This feature is <b>Coming soon...</b>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === EManageType.Users && <Users />}
          </section>
        </section>
      </StyledManage>
    </Layout>
  );
};
