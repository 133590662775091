import styled from 'styled-components';

export const StyledResults = styled.section`
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin: 20px auto;

  .results {
    &-header-tabs {
      margin: 5px 0;
    }

    &-upload {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }

    &-listing {
      &-title {
        color: var(--color-btn-text);
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 15px;
      }

      p {
        color: var(--text-color);
        font-size: 0.9rem;
      }
    }

    &-export {
      flex: 1;
      padding: 20px;
      max-width: 400px;

      &-btns {
        margin-top: 10px;
      }
    }

    &-title {
      color: var(--green);
      font-size: 1.2rem;
      font-weight: 600;
    }

    &-card {
      border: 1px solid var(--border-color);
      border-radius: 15px;
      padding: 15px;
      margin-top: 10px;
    }

    &-description {
      font-size: 0.85rem;
      color: var(--text-color);
      margin-bottom: 10px;

      p {
        margin: 0;
      }
    }
  }

  form {
    border: 1px solid var(--border-color);
    border-radius: 15px;
    padding: 15px;
    padding: 20px;
    flex: 1;
    max-width: 600px;
    margin: 20px 0;
  }

  .form-group-btns {
    margin: 0;
    padding: 0;
  }
`;
