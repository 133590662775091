import axios from 'axios';
import { SharedService } from 'services/shared';
import { IAddExamPayload, IExam, IGetExamsRes, IUpdateExamPayload } from './index.models';

class ExamService extends SharedService {
  async createExam(data: IAddExamPayload, callback: (data: { data: IExam; message: string }) => void) {
    const res = await axios.post(`${this.getApiBase()}/exams/`, data, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data);
  }
  async getExams(school_id: string, callback: (data: IExam[]) => void) {
    const res = await axios.get<IGetExamsRes>(`${this.getApiBase()}/exams/?school_id=${school_id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async updateExam(data: IUpdateExamPayload, callback: (data: { data: IExam; message: string }) => void) {
    const { id, ...rest } = data;
    const res = await axios.patch(`${this.getApiBase()}/exams/${id}`, rest, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data);
  }

  async archiveExam(
    data: {
      id: string;
      school_id: string;
    },
    callback: (data: { message: string }) => void
  ) {
    const res = await axios.delete(`${this.getApiBase()}/exams/${data.id}?school_id=${data.school_id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.message);
  }
}

export const examService = new ExamService();
