import { IStudentBadge } from 'pages/student/sections/badges';
import { useEffect, useState } from 'react';
import { studentsService } from 'services';

export interface IStudentBadges {
  academic: {
    type: number;
    badges: IStudentBadge[];
    count: number;
  };
  behavioural: {
    type: number;
    badges: IStudentBadge[];
    count: number;
  };
  attendance: {
    type: number;
    badges: IStudentBadge[];
    count: number;
  };
  cocurricular: {
    type: number;
    badges: IStudentBadge[];
    count: number;
  };
}

const defaultBadges = {
  academic: {
    type: 1,
    badges: [],
    count: 0
  },
  behavioural: {
    type: 2,
    badges: [],
    count: 0
  },
  attendance: {
    type: 3,
    badges: [],
    count: 0
  },
  cocurricular: {
    type: 4,
    badges: [],
    count: 0
  }
} as IStudentBadges;

export const useBadges = (data: { student_id: string; class_id: string }) => {
  const [badges, setBadges] = useState<IStudentBadges>(defaultBadges);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    studentsService.getBadges(data, badges => {
      setBadges(badges);
      setLoading(false);
    });
  }, [data]);

  return { badges, loading };
};
