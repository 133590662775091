import { IDate } from 'models/index.model';

export interface INewsLetter {
  id: string;
  title: string;
  content: string;
  date_created: IDate;
  date_updated: IDate;
  status?: ENewsLetterStatus;
  year?: number;
  school_id?: string;
  attachments: INewsLetterFile[];
  views: string[];
}

export interface INewsLetterUpdatePayload {
  title?: string;
  content?: string;
  publish?: boolean;
  attachments?: INewsLetterFile[];
  status?: ENewsLetterStatus;
  year: number;
  school_id: string;
}

export enum ENewsLetterStatus {
  Active = 1,
  Archived = 2,
  Draft = 3,
  Sscheduled = 4,
  All = 5
}

export enum ENewsLetterStatusLabel {
  Active = 'Active',
  Archived = 'Archived',
  Draft = 'Draft',
  Sscheduled = 'Scheduled',
  All = 'All',
  Create = 'Create'
}
export interface INewsLetterCreate {
  school_id: string;
  title: string;
  content: string;
  publish: boolean;
  attachments?: INewsLetterFile[];
}

export interface INewsLetterFile {
  url: string;
  type: string;
  name: string;
}

export interface INewsLetterUpdateResponse {
  data: {
    id: string;
  };
  message: string;
}
