export interface Result {
  gender: string;
  year: string;
  class_id: string;
  last_name: string;
  weight: string;
  score: string;
  total_marks: number;
  school_id: string;
  exam: string;
  subject: string;
  term: string;
  admission_no: string;
  first_name: string;
  date_updated: {
    long_date: string;
    short_date: string;
    time_since: string;
  };
  date_created: {
    long_date: string;
    short_date: string;
    time_since: string;
  };
  id: string;
  final_score: number;
  exam_score: number;
  remark: string;
  remark_score: number;
  naration: string;
  naration_score: number;
  type: ResultType;
}

export interface ResultUploadRequestPayload {
  file_name: string;
  class_id: string;
  school_id: string;
  term: string;
  subject: string;
  exam_id: string;
  exam_date: string;
  total_marks: number;
  type: ResultType;
}

export enum RankingView {
  Students = 1,
  Summary = 2,
  Male = 3,
  Female = 4,
  Other = 5
}

export enum ResultType {
  Graded = 'graded',
  Narartion = 'naration'
}
