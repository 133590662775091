import styled from "styled-components";
const StyledInputError = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const InputError = ({ message, show }: { message: string; show: boolean }) => {
  return show ? <StyledInputError>{message}</StyledInputError> : null;
};

export default InputError;
