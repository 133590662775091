import { Button } from '@primer/react';
import { Dialog, Table } from 'components';
import { CoreContext } from 'contexts';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { examService } from 'services/exams';
import { ExamStatus, IExam } from 'services/exams/index.models';
import styled from 'styled-components';

const StyledExamList = styled.section`
  .exams-table-ctas {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .exams-edit-info {
    background-color: var(--warning-100);
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid var(--warning-300);
    margin-bottom: 20px;
    font-size: 0.8rem;
    color: var(--warning-600);
  }

  .exams-table-td-flex {
    cursor: pointer;
    gap: 3px;
  }
`;

export const ExamList = ({ data }: { data: IExam[] }) => {
  const { setShowLineLoader, user, setExamList } = useContext(CoreContext);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedExam, setSelectedExam] = useState<IExam | null>(null);

  return (
    <StyledExamList>
      <Table
        showCount={false}
        totalItems={data.length}
        header={[
          {
            label: 'Subject'
          },
          {
            label: 'Marks'
          },
          {
            label: 'Weight'
          },
          {
            label: 'Created'
          },
          {
            label: 'Last Updated'
          },
          {
            label: 'Actions'
          }
        ]}
        pagination={{ show: false }}
        data={data.map(item => (
          <tr key={item.id}>
            <td style={{ textTransform: 'capitalize' }}>{item.subject.name}</td>
            <td>{item.total_marks ?? 0}</td>
            <td>{item.weight}%</td>
            <td>{item.date_created.short_date}</td>
            <td>{item.date_updated.time_since}</td>
            <td>
              <div className="exams-table-ctas">
                <Button
                  disabled={item.status === ExamStatus.Archived}
                  onClick={() => {
                    setSelectedExam(item);
                    setShowDialog(true);
                  }}
                  size="small"
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={async () => {
                    try {
                      if (user && item.id) {
                        setShowLineLoader(true);
                        await examService.updateExam(
                          {
                            id: item.id,
                            name: item.name,
                            school_id: user.uid,
                            status: item.status === ExamStatus.Active ? ExamStatus.Archived : ExamStatus.Active
                          },
                          data => toast.success(data.message)
                        );
                        await examService.getExams(user.uid, data => {
                          setExamList(data);
                          setShowLineLoader(false);
                        });
                      }
                    } catch (error) {
                      if (error instanceof Error) toast.error(error.message);
                      setShowLineLoader(false);
                    }
                  }}
                  size="small"
                >
                  {item.status === ExamStatus.Active ? 'Archive' : 'Unarchive'}
                </Button>
              </div>
            </td>
          </tr>
        ))}
      />
      <Dialog
        floated={true}
        showDialog={showDialog && selectedExam !== null}
        onClose={() => {
          setShowDialog(false);
          setSelectedExam(null);
        }}
        title="Edit Exam"
        dialogContent={
          <div className="exams-edit">
            <div className="exams-edit-info">
              Please <b>make sure</b> you appropriately set the weight of the exam. This will be used to calculate the
              final score of the student.{' '}
              <i>
                For e,g <b>cat 1 = 30%, cat 2 = 30%, exam = 40%</b>
              </i>
            </div>
            <form
              className="exams-edit-form"
              onSubmit={async (event: any) => {
                event.preventDefault();
                try {
                  const { name, weight, marks } = event.target;
                  if (user && name.value && selectedExam) {
                    setShowLineLoader(true);
                    await examService.updateExam(
                      {
                        name: name.value,
                        weight: Number(weight.value ?? selectedExam.weight),
                        school_id: user.uid,
                        id: selectedExam.id,
                        status: selectedExam.status,
                        total_marks: Number(marks.value ?? selectedExam.total_marks ?? 0)
                      },
                      data => {
                        toast.success(data.message);
                        event.target.reset();
                      }
                    );
                    await examService.getExams(user.uid, data => {
                      setExamList(data);
                      setShowLineLoader(false);
                      setShowDialog(false);
                    });
                  }
                } catch (error) {
                  if (error instanceof Error) toast.error(error.message);
                  setShowLineLoader(false);
                }
              }}
            >
              <div className="form-group">
                <label htmlFor="student_first_name" className="form-label">
                  Exam Name
                </label>
                <input
                  name="name"
                  id="student_first_name"
                  className="form-input"
                  defaultValue={selectedExam?.name}
                  placeholder={selectedExam?.name ?? 'e.g Mid Term Exam'}
                  type="text"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="marks" className="form-label">
                  Total Marks
                </label>
                <input
                  name="marks"
                  id="marks"
                  className="form-input"
                  defaultValue={selectedExam?.total_marks?.toString()}
                  placeholder={selectedExam?.total_marks?.toString() ?? 'e.g 100'}
                  type="number"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="weight" className="form-label">
                  Exam Weight (in %)
                </label>
                <input
                  name="weight"
                  id="weight"
                  type="number"
                  max={100}
                  min={1}
                  defaultValue={selectedExam?.weight?.toString()}
                  className="form-input"
                  placeholder={selectedExam?.weight?.toString() ?? 'e.g 40'}
                  required={true}
                />
              </div>
              <Button type="submit">Update Exam</Button>
            </form>
          </div>
        }
      />
    </StyledExamList>
  );
};
