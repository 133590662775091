import { SearchIcon } from '@primer/octicons-react';
import './index.css';

const SearchInput = ({
  onChange = () => {},
  placeholder = 'Search...',
  defaultValue = ''
}: {
  onChange?: (event: any) => void;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
}) => {
  return (
    <div className="app-search">
      <input className="app-search-input" type="text" onChange={onChange} placeholder={placeholder} />
      <SearchIcon />
    </div>
  );
};

export default SearchInput;
