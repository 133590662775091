import { SignOutIcon } from '@primer/octicons-react';
import { CSSProperties, useState } from 'react';
import { coreService } from 'services';
import './index.css';

const Logout = () => {
  const handleLogout = () => {
    coreService.signOut();
  };
  return (
    <div className="header-avatar" onClick={handleLogout}>
      <SignOutIcon size={24} />
    </div>
  );
};

const GroupedButtons = ({
  style = {},
  className = '',
  buttons = [
    {
      label: 'Sales',
      type: 'button'
    },
    {
      label: 'Payouts',
      type: 'button'
    }
  ],
  clickHandler = (label: string) => {
    console.log(label);
  }
}: {
  className?: string;
  buttons?: { label: string; type?: 'button' | 'button' | 'reset' }[];
  clickHandler?: (label: string) => void;
  style?: CSSProperties;
}) => {
  const [active, setActive] = useState(0);
  return (
    <div style={style} className={`btn-grouped ${className}`}>
      {buttons.map((item, index) => (
        <button
          type={item.type}
          key={`btn-${index}-${item.label}`}
          className={`btn-grouped-item ${active === index ? 'btn-grouped-item-active' : ''}`}
          onClick={event => {
            event.preventDefault();
            setActive(index);
            clickHandler(item.label.trim().split(' ').join('-').toLowerCase());
          }}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export { GroupedButtons, Logout };
