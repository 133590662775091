import axios from 'axios';
import { IGetTeachersResponse, ITeacher, IUpdateTeacherPayload } from 'pages/schools/models/index.models';
import { SharedService } from 'services/shared';
import { ICreateTeacherPayload } from './index.models';

class TeachersService extends SharedService {
  async getTeachers(payload: { school_id: string; class_id?: string }, callback: (data: ITeacher[]) => void) {
    try {
      const search = payload.class_id ? `?class_id=${payload.class_id}` : '';
      const res = await axios.get<IGetTeachersResponse>(
        `${this.getApiBase()}/teachers/school/${payload.school_id}/${search}`,
        {
          headers: {
            Authorization: `Bearer ${await this.getIdToken()}`
          }
        }
      );
      if (!res.data.data) throw new Error(res.data.message);
      callback(res.data.data);
    } catch (error) {
      throw error;
    }
  }

  async createTeacher(school_id: string, payload: ICreateTeacherPayload, callback: (data: ITeacher) => void) {
    try {
      const res = await axios.post(`${this.getApiBase()}/teachers/school/${school_id}`, payload, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      if (!res.data.data) throw new Error(res.data.message);
      callback(res.data.data);
    } catch (error) {
      throw error;
    }
  }

  async updateTeacher(
    school_id: string,
    id: string,
    payload: IUpdateTeacherPayload,
    callback: (data: ITeacher) => void
  ) {
    try {
      const res = await axios.patch(`${this.getApiBase()}/teachers/${school_id}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      if (!res.data.data) throw new Error(res.data.message);
      callback(res.data.data);
    } catch (error) {
      throw error;
    }
  }

  async removeFromClass(teacher_id: string, class_id: string, callback: () => void) {
    try {
      const res = await axios.patch(
        `${this.getApiBase()}/teachers/class/remove/${teacher_id}/`,
        {
          class_id
        },
        {
          headers: {
            Authorization: `Bearer ${await this.getIdToken()}`
          }
        }
      );
      if (!res.data.data) throw new Error(res.data.message);
      callback();
    } catch (error) {
      throw error;
    }
  }
}

export const teachersService = new TeachersService();
