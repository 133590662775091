import axios from 'axios';
import { IPagination } from 'models/index.model';
import { SharedService } from 'services/shared';
import { Result, ResultType, ResultUploadRequestPayload } from './index.model';
import { IGetResultsPayload, IRankingResponse } from 'pages/class/sections/results/index.model';

class ResultsService extends SharedService {
  async uploadResults(data: ResultUploadRequestPayload, callback: (arg0: any) => void) {
    const res = await axios.post(
      `${this.getApiBase()}/results/upload`,
      {
        ...data,
        year: new Date().getFullYear().toString()
      },
      {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      }
    );
    if (res.data.data) callback(res.data.data);
    else throw new Error(res.data.message);
  }

  async exportResultsTemplate(
    data: {
      school_id: string;
      class_id: string;
      type: ResultType;
    },
    callback: (arg0: string) => void
  ) {
    try {
      const res = await axios.post(`${this.getApiBase()}/results/export/template`, data, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      if (res.data.data) callback(res.data.data);
      else throw new Error(res.data.message);
    } catch (error) {
      throw error;
    }
  }

  async getResults(type: 'class' | 'school', id: string, year: string, callback: (arg0: any[]) => void) {
    try {
      switch (type) {
        case 'class':
          const classRes = await axios.get(`${this.getApiBase()}/results/?year=${year}`, {
            headers: {
              Authorization: `Bearer ${await this.getIdToken()}`
            }
          });
          callback(classRes && classRes.data ? classRes.data : []);
          break;
        case 'school':
          const schoolRes = await axios.get(`${this.getApiBase()}/results/?school_id=${id}&?year=${year}`, {
            headers: {
              Authorization: `Bearer ${await this.getIdToken()}`
            }
          });
          callback(schoolRes && schoolRes.data ? schoolRes.data : []);
          break;
        default:
          break;
      }
    } catch (error) {
      throw error;
    }
  }

  async getStudentResults(
    query: {
      admission_no: string;
      school_id: string;
      year?: number | string | null;
      subject?: string | number | null;
      term?: string | number | null;
      class_id?: string;
      page?: number;
      size?: number;
      exam_id?: string;
      order_by?: string;
      direction?: 'asc' | 'desc';
    },
    callback: (data: { data: Result[]; pagination: IPagination }) => void
  ) {
    const queryStr = this.getQueryString(query);
    const url = `${this.getApiBase()}/results/?${queryStr}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback({
      data: res.data.data,
      pagination: res.data.pagination
    });
  }

  async getStudentsResultsRanking(query: IGetResultsPayload, callback: (data: any[]) => void) {
    const queryStr = this.getQueryString(query);
    const url = `${this.getApiBase()}/results/ranking/?${queryStr}`;
    const res = await axios.get<IRankingResponse>(url, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }
}

const resultsService = new ResultsService();
export default resultsService;
