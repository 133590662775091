import { useContext, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import { Alert } from '../../components';
import { CoreContext } from '../../contexts';
import { coreService, dbService } from '../../services';
import './index.css';

const Auth = () => {
  const { user, loading, setRole, setShowAlert, showAlert, setShowLineLoader } = useContext(CoreContext);
  const [authenticating, setAuthenticating] = useState(false);
  const [error, setError] = useState('');
  const [authEmail, setAuthEmail] = useState('');

  const handleLogin = async (event: any) => {
    event.preventDefault();
    try {
      setError('');
      setAuthenticating(true);
      const { email, password } = event.target.elements;
      const res = await coreService.checkUserType(email.value);
      if (res.role === 'admin' || res.role === 'school') {
        setRole(res.role);
        await coreService.signIn(email.value, password.value);
      } else {
        setAuthenticating(false);
        setError(`You're not authorized to access this app`);
      }
    } catch (error) {
      setAuthenticating(false);
      if (error instanceof Error) setError(error.message ?? 'Something went wrong. Please try again.');
    }
  };

  const handlePasswordReset = async (event: any) => {
    event.preventDefault();
    try {
      const validEmail = authEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      if (!validEmail) {
        setShowAlert({
          show: true,
          type: 'error',
          title: 'Error',
          message: 'Please enter a valid email address'
        });
        return;
      }
      setShowLineLoader(true);
      await dbService.sendPasswordResetEmail(dbService.auth, authEmail);
      setShowAlert({
        show: true,
        type: 'info',
        title: 'Email Sent',
        message: 'Please check your email for a password reset link'
      });
    } catch (error: any) {
      setShowAlert({
        show: true,
        type: 'error',
        title: 'Error',
        message: `${error.code} ${error.message}`
      });
    } finally {
      setShowLineLoader(false);
    }
  };

  return user ? (
    <Navigate to="/" />
  ) : loading ? (
    <section className="loader">
      <RotatingLines strokeColor="#ddd" />
      <div className="loader-text">
        <p>Recess {new Date().getFullYear()}</p>
      </div>
    </section>
  ) : (
    <section className="auth">
      <Alert
        show={showAlert.show}
        title={showAlert.title}
        message={showAlert.message}
        type={showAlert.type}
        onClose={() => setShowAlert({ ...showAlert, show: false })}
      />
      <section className="auth-form">
        <div className="auth-form-header">
          <div className="auth-form-title">Recess Central</div>
          <p className="auth-form-subtitle">Login as an admin or school</p>
          <div className="auth-form-subtitle-error">
            <i>{error}</i>
          </div>
        </div>
        <form className="form" onSubmit={handleLogin}>
          <div className="form-group">
            <label className="form-label">Email</label>
            <input
              onChange={e => setAuthEmail(e.target.value)}
              name="email"
              className="form-input"
              type="email"
              required={true}
              autoComplete="username"
            />
          </div>
          <div className="form-group">
            <label className="form-label">Password</label>
            <input
              name="password"
              className="form-input"
              type="password"
              required={true}
              autoComplete="current-password"
            />
          </div>

          <button type="submit" className="button btn-md" disabled={authenticating}>
            Login
          </button>
          <div className="form-group">
            <div className="auth-form-reset-password">
              Forgot your password? <span onClick={handlePasswordReset}>Reset</span>
            </div>
          </div>
        </form>
        <div className="auth-form-footer">
          <p className="auth-form-subtitle">All rights reserved. Recess {new Date().getFullYear()}</p>
        </div>
      </section>
    </section>
  );
};

export default Auth;
