import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

export interface IFiltersProps {
  label: string;
  id: string;
  options: {
    label: string;
    value: string;
  }[];
  defaultValue: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectFilters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  .filters {
    &-label {
      font-size: 0.8rem;
    }

    &-select {
      padding: 3px 10px;
      border-radius: 3px;
      border: 1px solid var(--tb-border-color);
      min-width: 100px;

      &:focus {
        border-color: var(--tb-btn-hover-bg-color);
      }
    }
  }
`;

export const FilterSelect = ({ label, options, defaultValue, onChange }: IFiltersProps) => (
  <SelectFilters>
    <span className="filters-label">{label}</span>
    <select className="filters-select" defaultValue={defaultValue} onChange={onChange}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </SelectFilters>
);

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0;
`;

export const GroupedFilters = ({
  filterGroups,
  onChange
}: {
  filterGroups: IFiltersProps[];
  onChange: (selectedFilters: { [key: string]: string }) => void;
}) => {
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleFilterChange = (groupLabel: string, event: ChangeEvent<HTMLSelectElement>) => {
    const updatedFilters = { ...selectedFilters, [groupLabel]: event.target.value };
    setSelectedFilters(updatedFilters);
    onChange(updatedFilters);
  };

  return (
    <FiltersContainer>
      {filterGroups.map((group, index) => (
        <FilterSelect key={index + group.id} {...group} onChange={event => handleFilterChange(group.id, event)} />
      ))}
    </FiltersContainer>
  );
};
