import './index.css';

const GeneralStatsSkeleton = () => {
  return (
    <section className="general-stats">
      {[1, 2, 3, 4].map((_, i) => (
        <div key={i} className="general-stats-item">
          <div className="general-stats-item-title"></div>
          <div className="general-stats-item-value"></div>
        </div>
      ))}
    </section>
  );
};

const NewsLettersSkeleton = () => {
  return (
    <section className="news-letter-cards">
      {[1, 2, 3, 4, 5].map((_, i) => (
        <div key={i} className="news-letter-card">
          <div className="news-letter-card-title"></div>
          <div className="news-letter-card-content"></div>
        </div>
      ))}
    </section>
  );
};

export { GeneralStatsSkeleton, NewsLettersSkeleton };
