import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #e1e4e8;
`;

interface NavLinkProps {
  active: 'true' | 'false';
  to?: string;
}

export const NavLink = styled.div<NavLinkProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => (props.active === 'true' ? '#0366d6' : '#586069')};
  text-decoration: none;
  border-bottom: ${props => (props.active === 'true' ? '2px solid #0366d6' : 'none')};

  &:hover {
    color: #0366d6;
    border-bottom: 2px solid #0366d6;
  }
`;

export const NavList = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
  flex-grow: 1;
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  background-color: #fff;
`;

export const NavListItem = styled.div<{
  active: 'true' | 'false';
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => (props.active === 'true' ? '#00bcd4' : '#586069')};
  font-weight: ${props => (props.active === 'true' ? '600' : '400')};
  text-decoration: none;
  border-bottom: 1px solid #e1e4e8;
  user-select: none;

  &:hover {
    color: #00bcd4;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const NavListLink = styled.a<{
  active: 'true' | 'false';
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => (props.active === 'true' ? '#0366d6' : '#586069')};
  text-decoration: none;
  border-bottom: ${props => (props.active === 'true' ? '2px solid #0366d6' : 'none')};

  &:hover {
    color: #00bcd4;
    border-bottom: 2px solid #0366d6;
  }
`;
