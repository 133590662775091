import axios from 'axios';
import { IStudentBadges } from 'hooks/badges';
import { IBehavioralNote } from 'hooks/behavioral';
import { IPagination } from 'models/index.model';
import { SharedService } from 'services/shared';
import {
  IAddStudentPayload,
  IMoveStudentsRequest,
  IMoveStudentsResponse,
  IStudent,
  IUpdateStudent,
  IUploadParentsRequest,
  IUploadParentsResponse,
  IUploadParentsResponse2
} from './index.models';

class StudentsService extends SharedService {
  async uploadParents(
    data: IUploadParentsRequest,
    callback: (arg0: { errors: IUploadParentsResponse2[]; success: IUploadParentsResponse2[]; message: string }) => void
  ) {
    const res = await axios.post<IUploadParentsResponse>(`${this.getApiBase()}/parents`, data, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data)
      callback({
        ...res.data.data,
        message: res.data.message
      });
    else throw new Error(res.data.message);
  }

  async createStudent(data: IAddStudentPayload, callback: (arg0: IStudent) => void) {
    const res = await axios.post(`${this.getApiBase()}/students/create`, data, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getClassStudents(
    school_id: string,
    class_id: string,
    pagination: { page: number; size: number },
    callback: (data: { data: IStudent[]; pagination: IPagination }) => void
  ) {
    const res = await axios.get(
      `${this.getApiBase()}/students/?school_id=${school_id}&class_id=${class_id}&page=${pagination.page}&size=${
        pagination.size
      }`,
      {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      }
    );
    if (res.data.data)
      callback({
        pagination: res.data.pagination,
        data: res.data.data
      });
    else throw new Error(res.data.message);
  }

  async getSchoolStudents(
    school_id: string,
    pagination: { page: number; size: number },
    callback: (res: { data: IStudent[]; pagination: IPagination }) => void
  ) {
    const res = await axios.get(
      `${this.getApiBase()}/students/?school_id=${school_id}&page=${pagination.page}&size=${pagination.size}`,
      {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      }
    );
    if (res.data.data)
      callback({
        pagination: res.data.pagination,
        data: res.data.data
      });
    else throw new Error(res.data.message);
  }

  async getStudent(student_id: string, callback: (arg0: IStudent) => void) {
    const res = await axios.get(`${this.getApiBase()}/students/${student_id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data.data);
    else throw new Error(res.data.message);
  }

  async updateStudent(student_id: string, data: Partial<IUpdateStudent>, callback: (arg0: IStudent) => void) {
    const res = await axios.patch(`${this.getApiBase()}/students/${student_id}`, data, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data.data);
    else throw new Error(res.data.message);
  }

  async archiveStudent(student_id: string, callback: (arg0: IStudent) => void) {
    const res = await axios.delete(`${this.getApiBase()}/students/${student_id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data.data);
    else throw new Error(res.data.message);
  }

  async deleteStudent(student_id: string, callback: (arg0: { data: string; message: string }) => void) {
    const res = await axios.delete<{
      message: string;
      data: string;
    }>(`${this.getApiBase()}/students/${student_id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data);
    else throw new Error(res.data.message);
  }

  async getBadges(
    payload: {
      student_id: string;
      class_id: string;
    },
    callback: (arg0: IStudentBadges) => void
  ) {
    const res = await axios.get<{
      message: string;
      data: IStudentBadges;
    }>(`${this.getApiBase()}/badges/?student_id=${payload.student_id}&class_id=${payload.class_id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data.data);
    else throw new Error(res.data.message);
  }

  async getBehavioral(
    payload: {
      student_id: string;
      class_id: string;
      year: number;
    },
    callback: (arg0: IBehavioralNote[]) => void
  ) {
    const res = await axios.get<{
      message: string;
      data: IBehavioralNote[];
    }>(
      `${this.getApiBase()}/behavioural-notes/?student_id=${payload.student_id}&class_id=${payload.class_id}&year=${
        payload.year
      }`,
      {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      }
    );
    if (res.data.data) callback(res.data.data);
    else throw new Error(res.data.message);
  }

  async moveStudents(data: IMoveStudentsRequest, callback: (arg0: string) => void) {
    const res = await axios.post<IMoveStudentsResponse>(`${this.getApiBase()}/students/move`, data, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.message);
  }
}

export const studentsService = new StudentsService();
