export enum EPermission {
  Read = 0,
  Write = 1,
  Read_Write = 2
}

export enum ERole {
  Admin = 'admin',
  School = 'school',
  Teacher = 'teacher',
  Parent = 'parent'
}

export interface IAdmin {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  permission: EPermission;
  date_created?: string;
  date_updated?: string;
}

export enum EManageType {
  Account = 1,
  Exams = 2,
  Users = 3,
  Curriculum = 4
}

export enum EUserRole {
  Admin = 1,
  Parent = 2,
  School = 3,
  Teacher = 4,
  SuperAdmin = 5
}

export enum EAdminRoleScope {
  Recess = 1,
  School = 2,
  Partner = 3
}

export enum ESchoolFeatures {
  Classes = 1,
  Newsletters = 2,
  Results = 3,
  Fees = 4,
  Attendance = 5,
  Calendar = 6,
  Library = 7,
  Manage = 8,
  Exams = 9,
  Curriculum = 10
}
