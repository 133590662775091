import { EManageType } from './models/index.models';

export const SCHOOL_TABS = [
  {
    label: 'Account',
    id: EManageType.Account
  },
  {
    label: 'Exams',
    id: EManageType.Exams
  },
  {
    label: 'Curriculum',
    id: EManageType.Curriculum
  }
  // {
  //   label: 'Users',
  //   id: EManageType.Users
  // }
];

export const ADMIN_TABS = [
  {
    label: 'Account',
    id: EManageType.Account
  }
  // {
  //   label: 'Users',
  //   id: EManageType.Users
  // }
];
