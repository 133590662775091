import { Button, Label, TabNav } from '@primer/react';
import { CoreContext } from 'contexts';
import { Layout } from 'layouts';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { calendarService, coreService } from 'services';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import {
  ECalendarDeadline,
  ECalendarRecurrence,
  ICalendarEvent,
  ICalendarEventFilter
} from '../../services/calendar/index.models';
import ReactQuill from 'react-quill';

const RECURRENCE = [
  {
    label: 'All',
    value: ECalendarRecurrence.All
  },
  {
    label: 'One time',
    value: ECalendarRecurrence.Once
  },
  {
    label: 'Daily',
    value: ECalendarRecurrence.Daily
  },
  {
    label: 'Weekly',
    value: ECalendarRecurrence.Weekly
  },
  {
    label: 'Monthly',
    value: ECalendarRecurrence.Monthly
  },
  {
    label: 'Yearly',
    value: ECalendarRecurrence.Yearly
  }
] as {
  label: string;
  value: ECalendarRecurrence;
}[];

const TABS = [
  {
    label: 'All',
    id: 'all'
  },
  {
    label: 'Today',
    id: 'today'
  },
  {
    label: 'Upcoming',
    id: 'upcoming'
  },
  {
    label: 'Past',
    id: 'past'
  }
] as {
  label: string;
  id: string;
  icon?: any;
}[];

const StyledCalendarCard = styled.div`
  .calendar-card {
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    background-color: var(--white-color);
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-color);
      padding: 10px 20px;
    }

    &-title {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
      color: var(--green);
    }

    &-description {
      font-size: 0.9rem;
      font-weight: 400;
      margin: 0;
      padding: 10px 20px;
      color: var(--text-color);
      border-bottom: 1px solid var(--border-color);
    }

    &-footer {
      display: flex;
      justify-content: flex-start;
      padding: 10px 20px;
    }
  }
`;

const StyledEvents = styled.section`
  display: flex;
  aalign-items: flex-start;
  gap: 20px;
  padding: 20px;

  .calendar-create {
    flex: 1;
    max-width: 500px;

    &-form {
      box-shadow: var(--box-shadow);
      background-color: var(--white-color);
      padding: 20px;
      border-radius: 8px;
    }

    &-title {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem;
      color: var(--green);
    }
  }

  .calendar-tabs {
    flex: 1;
  }

  .calendar-events {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }
`;

export const Calendar = () => {
  const navigate = useNavigate();

  const { role, setShowAlert, user, setShowLineLoader } = useContext(CoreContext);
  const [activeTab, setActiveTab] = useState<string>('all');
  const [filters, setFilters] = useState<ICalendarEventFilter>({
    year: new Date().getFullYear(),
    recurrence: ECalendarRecurrence.All
  });
  const [events, setEvents] = useState<{
    [key: string]: ICalendarEvent[];
  }>({
    upcoming: [],
    past: [],
    all: [],
    today: []
  });
  const [data, setData] = useState<ICalendarEvent[]>([]);
  const [eventCreated, setEventCreated] = useState<boolean>(false);
  const [eventDeleted, setEventDeleted] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (role !== 'school') navigate('/');

    (async () => {
      try {
        setShowLineLoader(true);
        await calendarService.getEvents(
          { school_id: user?.uid ?? '', year: filters.year, recurrence: filters.recurrence },
          res => {
            const upcoming = res.filter(event => event.status === ECalendarDeadline.Future);
            const past = res.filter(event => event.status === ECalendarDeadline.Past);
            const today = res.filter(event => event.status === ECalendarDeadline.Today);
            setEvents({
              all: res,
              today,
              upcoming,
              past
            });
            setData(res);
            setEventCreated(false);
            setEventDeleted(false);
          }
        );
        setShowLineLoader(false);
      } catch (error: any) {
        setData([]);
        setShowLineLoader(false);
        setShowAlert({
          show: true,
          message: error.message ?? 'Sorry, something went wrong',
          title: 'Error',
          type: 'error'
        });
      }
    })();
  }, [
    filters.recurrence,
    filters.year,
    navigate,
    role,
    setShowAlert,
    setShowLineLoader,
    user?.uid,
    eventCreated,
    eventDeleted
  ]);

  useEffect(() => {
    setData(events[activeTab]);
  }, [activeTab, events]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const { title, start_date, end_date, time, recurrence } = event.target.elements;
      setShowLineLoader(true);
      const payload = {
        school_id: user?.uid ?? '',
        title: title.value,
        description: description,
        start_date: start_date.value,
        end_date: end_date.value,
        time: time.value,
        recurrence: Number(recurrence.value)
      };
      await calendarService.createEvent(payload, () => {
        setShowLineLoader(false);
        setEventCreated(true);
        setDescription('');
        event.target.reset();
        setShowAlert({
          show: true,
          message: 'Event created successfully',
          title: 'Success',
          type: 'success'
        });
      });
    } catch (error: any) {
      setShowLineLoader(false);
      setShowAlert({
        show: true,
        message: error.message ?? 'Sorry, something went wrong',
        title: 'Error',
        type: 'error'
      });
    }
  };
  return (
    <Layout
      back={{
        label: 'Back to home',
        link: '/'
      }}
      className="calendar"
    >
      <StyledEvents>
        <section className="calendar-create">
          <div className="calendar-create-form">
            <div className="calendar-create-title">Create an event</div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  minLength={10}
                  name="title"
                  className="form-input"
                  id="title"
                  required
                  placeholder="e.g Parents Meeting"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <ReactQuill
                  placeholder="e.g Parents Meeting will be held at the school hall"
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                />
              </div>
              <div className="form-group-flex">
                <div className="form-group">
                  <label htmlFor="start-date" className="form-label">
                    From
                  </label>
                  <input
                    name="start-date"
                    className="form-input"
                    id="start_date"
                    required
                    type="date"
                    min={new Date().toISOString().split('T')[0]}
                    defaultValue={new Date().toISOString().split('T')[0]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="end-date" className="form-label">
                    To
                  </label>
                  <input
                    name="end-date"
                    className="form-input"
                    id="end_date"
                    required
                    type="date"
                    defaultValue={new Date().toISOString().split('T')[0]}
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="time" className="form-label">
                  Time
                </label>
                <input name="time" className="form-input" id="time" required type="time" />
              </div>
              <div className="form-group">
                <label htmlFor="recurrence" className="form-label">
                  Recurrence
                </label>
                <select name="recurrence" className="form-input" id="recurrence" required>
                  {RECURRENCE.filter(item => item.value !== ECalendarRecurrence.All).map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group-btns">
                <Button type="submit">Create</Button>
              </div>
            </form>
          </div>
        </section>
        <section className="calendar-tabs">
          <TabNav aria-label="Main">
            {TABS.map(tab => (
              <TabNav.Link
                onClick={() => setActiveTab(tab.id)}
                key={tab.id}
                selected={activeTab === tab.id}
                className={activeTab === tab.id ? 'tab-active' : ''}
                sx={{
                  color: activeTab === tab.id ? '#1cbc96' : '#000',
                  fontWeight: activeTab === tab.id ? 'bold' : 'normal',
                  cursor: 'pointer'
                }}
              >
                {tab.icon ?? ''} {tab.label} ({events[tab.id].length})
              </TabNav.Link>
            ))}
          </TabNav>
          <div className="calendar-events-filters">
            <div
              style={{
                marginTop: 15
              }}
              className="form-group-flex"
            >
              <div className="form-group">
                <label htmlFor="time" className="form-label">
                  Filter by year
                </label>
                <select
                  name="year"
                  className="form-input"
                  id="year"
                  required
                  onChange={e => {
                    setFilters({
                      ...filters,
                      year: Number(e.target.value)
                    });
                  }}
                >
                  {coreService.getYearsFromNow(5).map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="start-date" className="form-label">
                  Filter by Recurrence
                </label>
                <select
                  name="recurrence"
                  className="form-input"
                  id="recurrence"
                  required
                  onChange={e => {
                    setFilters({
                      ...filters,
                      recurrence: Number(e.target.value)
                    });
                  }}
                >
                  {RECURRENCE.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="calendar-events">
            {data.map(event => (
              <StyledCalendarCard key={event.id}>
                <div className="calendar-card">
                  <div className="calendar-card-header">
                    <div className="calendar-card-title">{event.title}</div>
                    <Label variant="secondary">
                      {event.start_date.short_date} - {event.end_date.short_date}
                    </Label>
                  </div>
                  <div
                    className="calendar-card-description"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(event.description)
                    }}
                  ></div>

                  <div className="calendar-card-footer">
                    <Button
                      type="button"
                      onClick={async () => {
                        try {
                          setShowLineLoader(true);
                          await calendarService.deleteEvent(
                            {
                              school_id: user?.uid ?? '',
                              event_id: event.id,
                              year: event.year
                            },
                            () => {
                              setShowLineLoader(false);
                              setEventDeleted(true);
                              setShowAlert({
                                show: true,
                                message: 'Event deleted successfully',
                                title: 'Success',
                                type: 'success'
                              });
                            }
                          );
                        } catch (error: any) {
                          setShowLineLoader(false);
                          setShowAlert({
                            show: true,
                            message: error.message ?? 'Sorry, something went wrong',
                            title: 'Error',
                            type: 'error'
                          });
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </StyledCalendarCard>
            ))}
          </div>
        </section>
      </StyledEvents>
    </Layout>
  );
};
