import axios from 'axios';
import { SharedService } from 'services/shared';
import coreService from '../core';
import { IGeneralStats, IMonthlyStats, ISchoolGeneralStats, IStudentStats } from './index.models';

class StatsService extends SharedService {
  async getStudentStats(callback: (data: IStudentStats) => void) {
    const idToken = await coreService.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/students/profile`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getAllStudentsStats(callback: (data: IStudentStats) => void) {
    const idToken = await coreService.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/stats/students`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getMonthlyStats(callback: (data: IMonthlyStats) => void) {
    const idToken = await coreService.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/stats/monthly`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getGeneralStats(callback: (data: IGeneralStats) => void) {
    const idToken = await coreService.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/stats/general`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getSchoolGeneralStats(schoolId: string, callback: (data: ISchoolGeneralStats) => void) {
    const idToken = await coreService.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/stats/school/general/${schoolId}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getSchoolMonthlyStats(schoolId: string, callback: (data: IMonthlyStats) => void) {
    const idToken = await coreService.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/stats/school/monthly/${schoolId}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getSchoolStudentStats(schoolId: string, callback: (data: IStudentStats) => void) {
    const idToken = await coreService.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/stats/school/students/${schoolId}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }
}

const statsService = new StatsService();
export default statsService;
