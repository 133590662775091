import { PlusIcon } from '@primer/octicons-react';
import { ReactNode, useEffect, useState } from 'react';
import './header.css';

const PageHeader = ({
  title = 'Recess',
  description = 'A platform for schools to manage their students',
  className = '',
  onAddClick = () => {},
  showActionButton = true,
  children,
  actionButtonLabel = 'Add',
  showMenuList = false,
  menuList = [],
  disableActionButton = false,
  loading = false,
  logo = ''
}: {
  title?: string;
  description?: string;
  children?: ReactNode;
  className?: string;
  actionButtonLabel?: string;
  showActionButton?: boolean;
  onAddClick?: () => void;
  showMenuList?: boolean;
  menuList?: { label: string; slug: string; disabled?: boolean; onClick: () => void }[];
  disableActionButton?: boolean;
  loading?: boolean;
  logo?: string;
}) => {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setShowMenu(showMenuList ? true : false);
  }, [showMenuList]);

  return (
    <div className={`content-header ${className}`}>
      {children ? (
        children
      ) : (
        <>
          <div className="content-header-title">
            {loading ? (
              <div className="skeleton-bg content-header-title-loading" />
            ) : (
              <div
                style={{
                  marginBottom: logo === '' ? '0' : 15
                }}
                className="content-header-title-text"
              >
                {logo !== '' ? <img className="school-logo" src={logo} alt="School logo" /> : null}
                <span className="content-header-title-text-value">{title}</span>
              </div>
            )}
            <div
              style={{
                position: 'relative'
              }}
            >
              {showActionButton && actionButtonLabel !== '' && (
                <button
                  disabled={disableActionButton}
                  className="button content-header-title-button"
                  onClick={showMenuList ? () => setShowMenu(prev => !prev) : onAddClick}
                >
                  <PlusIcon /> <span>{actionButtonLabel}</span>
                </button>
              )}
              {showMenu && menuList.length ? (
                <div className="select-menu">
                  {menuList.map(item => (
                    <div
                      key={item.slug}
                      className="select-menu-item"
                      style={{
                        opacity: item.disabled ? 0.5 : 1
                      }}
                      onClick={() => (item.disabled ? null : item.onClick())}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <div className="content-header-description">{description}</div>
        </>
      )}
    </div>
  );
};

export { PageHeader };
