import axios from 'axios';
import { SharedService } from 'services/shared';
import { ECalendarRecurrence, ICalendarEvent, ICreateCalendarEvent } from './index.models';

class CalendarService extends SharedService {
  async createEvent(payload: ICreateCalendarEvent, callback: (arg0: any) => void) {
    try {
      const res = await axios.post(`${this.getApiBase()}/calendar`, payload, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      if (res.data.data) callback(res.data.data);
      else throw new Error(res.data.message);
    } catch (error) {
      throw error;
    }
  }

  async getEvents(
    data: { school_id: string; year: string | number; recurrence?: ECalendarRecurrence },
    callback: (arg0: ICalendarEvent[]) => void
  ) {
    try {
      const optional_filter = data.recurrence && data.recurrence > 0 ? `&recurrence=${data.recurrence}` : '';
      const res = await axios.get<{
        data: ICalendarEvent[];
        message: string;
      }>(`${this.getApiBase()}/calendar/?school_id=${data.school_id}&year=${data.year}${optional_filter}`, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      if (res.data.data) callback(res.data.data);
      else throw new Error(res.data.message);
    } catch (error) {
      throw error;
    }
  }

  async deleteEvent(payload: { school_id: string; year: number; event_id: string }, callback: (arg0: any) => void) {
    try {
      const res = await axios.delete(`${this.getApiBase()}/calendar/${payload.event_id}`, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        },
        data: {
          school_id: payload.school_id,
          year: payload.year
        }
      });
      if (res.data.data) callback(res.data.data);
      else throw new Error(res.data.message);
    } catch (error) {
      throw error;
    }
  }
}

export const calendarService = new CalendarService();
