import counties from './json/kenyan-counties.json';

export const headers = {
  students: [
    {
      label: 'Admission No',
      width: 30
    },
    {
      label: 'Admission Date',
      width: 50
    },
    {
      label: 'Student Name',
      width: 50
    },
    {
      label: 'Class Name',
      width: 50
    },
    {
      label: 'Parent(Primary)',
      width: 50
    },
    {
      label: 'Onboarding',
      width: 50
    }
  ],
  teachers: [
    {
      label: 'Name',
      width: 80
    },
    {
      label: 'Email',
      width: 80
    },
    {
      label: 'Class',
      width: 80
    },
    {
      label: 'Date Created',
      width: 80
    }
  ],
  classes: [
    {
      label: 'Name of Class',
      width: 80
    },
    {
      label: 'Streamed',
      width: 80
    },

    {
      label: 'No of Subjects',
      width: 80
    },

    {
      label: 'Quick Actions',
      width: 175
    }
  ],
  counties: counties.sort((a, b) => (a.name > b.name ? 1 : -1))
};
