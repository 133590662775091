import { IDateDetails } from 'pages/schools/models/index.models';

export enum ECalendarType {
  School = 1,
  Class = 2
}

export enum ECalendarRecurrence {
  All = 0,
  Once = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
  Yearly = 5
}

export interface ICreateCalendarEvent {
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  time: string;
  recurrence: ECalendarRecurrence;
}

export interface ICalendarEventFilter {
  year: number;
  recurrence: ECalendarRecurrence;
}

export interface ICalendarEvent {
  id: string;
  title: string;
  description: string;
  start_date: IDateDetails;
  end_date: IDateDetails;
  time: string;
  status: ECalendarDeadline;
  year: number;
  author: {
    name: string;
    email: string;
  };
}

export enum ECalendarDeadline {
  Today = 1,
  Future = 2,
  Past = 3
}
