import { Button, ButtonGroup } from '@primer/react';
import { useState } from 'react';

export const TabNavSecondary = ({
  data,
  onSelect
}: {
  data: {
    label: string;
    value: string | number;
  }[];
  onSelect: (value: string | number) => void;
}) => {
  const [selected, setSelected] = useState<string | number>(data[0].value);
  return (
    <ButtonGroup
      style={{
        margin: '10px 0'
      }}
    >
      {data.map((item, index) => (
        <Button
          key={index}
          onClick={() => {
            setSelected(item.value);
            onSelect(item.value);
          }}
          style={{
            backgroundColor: selected === item.value ? 'var(--green)' : 'transparent',
            color: selected === item.value ? '#fff' : 'var(--green)',
            border: selected === item.value ? '1px solid var(--green)' : '1px solid var(--green)'
          }}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
