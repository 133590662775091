import { CoreContext } from 'contexts';
import { ReactNode, useContext } from 'react';
import { coreService } from 'services';
import { Alert, Header, Sidebar } from '../components';
import './layout.css';

const Layout = ({
  children,
  className = '',
  back
}: {
  children: ReactNode;
  className: string;
  back?: {
    label?: string;
    link: string;
  };
}) => {
  const { showAlert, setShowAlert } = useContext(CoreContext);

  return (
    <>
      <section className={`layout ${className} show-desktop`}>
        <Alert
          {...showAlert}
          onClose={() =>
            setShowAlert({
              ...showAlert,
              show: false
            })
          }
        />
        <Sidebar />
        <section className="layout-content">
          <Header back={back} />
          {children}
        </section>
      </section>
      <section className="show-mobile">
        <div className="mobile-alert">
          Hey there! This app is not optimized for mobile devices. Please use a desktop browser to view this app.
        </div>
        <div>
          <button
            className="button btn-primary"
            onClick={() => {
              coreService.signOut().then(() => {
                window.location.href = '/auth';
              });
            }}
          >
            Logout
          </button>
        </div>
      </section>
    </>
  );
};

export { Layout };
