export enum ESchoolStatus {
  All = 0,
  Active = 1,
  Inactive = 2,
  Suspended = 3
}

export enum ESchoolStatusLabels {
  'StatusLabel_1' = 'Active',
  'StatusLabel_2' = 'Inactive',
  'StatusLabel_3' = 'Suspended'
}

export interface ISchool {
  id: string;
  name: string;
  address: string;
  phone_number: string;
  email: string;
  code: string;
  curriculums: string[];
  county: string;
  photo_url: string;
  country: string;
  archived: boolean;
  status: ESchoolStatus;
  classes: IClass[];
  website?: string;
  type?: 'private' | 'public';
  level?: 'primary' | 'secondary' | 'tertiary';
  date_created: IDateDetails;
  date_updated: IDateDetails;
}

export interface UpdateSchoolResponse {
  data: ISchool | null;
  message: string;
}

export interface UpdateSchoolRequest {
  name?: string;
  email?: string;
  phone_number?: string;
  address?: string;
  county?: string;
  country?: string;
  code?: string;
  photo_url?: string;
  curriculums?: string[];
  status?: ESchoolStatus;
  archived?: boolean;
}

export interface IGetSchoolRequestQuery {
  page?: number;
  size?: number;
  search?: string;
  status?: ESchoolStatus;
  archived?: boolean;
}

export interface CreateSchoolPayload {
  name: string;
  address: string;
  code: string;
  county: string;
  country: string;
  level: 'primary' | 'secondary' | 'tertiary';
  type: 'private' | 'public';
  email: string;
  photo: File;
  phone_number: string;
  curriculums: string[];
}

export interface ISchoolCreateResponse {
  data: {
    email: string;
    id: string;
  };
  message: string;
}

export interface ISubject {
  id: string;
  name: string;
}

export interface IClass {
  id: string;
  school_id: string;
  code: string;
  name: string;
  full_name: string;
  age: string;
  streamed: boolean;
  stream?: string;
  subjects: ISubject[];
}

export interface ICreateClassResponse {
  data: {
    id: string;
  };
  message: string;
}

export interface IGetClassesResponse {
  data: IClass[];
  message: string;
}

export interface IGetClassResponse {
  data: IClass;
  message: string;
}

export interface IDateDetails {
  long_date: string;
  short_date: string;
  time_since: string;
  date: string;
}

export interface IClassItem {
  id: string;
  name: string;
  age: string;
  code: number;
  subjects: string[];
}

export interface ITeacher {
  role: string;
  school_id: string;
  last_name: string;
  class_teacher: string[];
  first_name: string;
  name: string;
  email: string;
  date_created: IDateDetails;
  date_updated: IDateDetails;
  classes: IClassItem[];
  id: string;
}

export interface ITeacherCreatePayload {}

export interface ITeacherCreateResponse {
  data: {
    email: string;
    id: string;
  };
  message: string;
}

export interface IGetTeachersResponse {
  data: ITeacher[];
  message: string;
}

export enum ETeacherRole {
  Teacher = 1,
  Admin = 2,
  SuperAdmin = 3
}

export enum EClassTeacher {
  Yes = 1,
  No = 2
}

export interface IUpdateTeacherPayload {
  class_id: string;
  subjects: string[];
  class_teacher: EClassTeacher;
}

export interface IUpdateClassPayload {
  id: string;
  data: {
    school_id: string;
    name?: string;
    subjects?: ISubject[];
    stream?: string;
  };
}
