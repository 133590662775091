import { XIcon } from '@primer/octicons-react';
import { CSSProperties } from 'react';
import './index.css';

const Dialog = ({
  title = 'Dialog Title',
  dialogContent = 'Dialog Content',
  showDialog = true,
  className = '',
  onClose = () => {},
  style = {},
  floated = false
}: {
  title?: string;
  dialogContent?: string | JSX.Element;
  showDialog?: boolean;
  onClose?: () => void;
  className?: string;
  style?: CSSProperties;
  floated?: boolean;
}) => {
  return (
    <section style={{ display: showDialog ? 'flex' : 'none' }} className="dialog-overlay">
      <section style={style} className={`dialog ${className} ${floated ? 'dialog-floated' : ''}`}>
        <header className="dialog-header">
          <div className="dialog-header-title">{title}</div>
          <div className="dialog-header-close" onClick={onClose}>
            <XIcon size={24} />
          </div>
        </header>
        <section
          style={{
            maxHeight: floated ? '100%' : 650,
            height: floated ? '100%' : 'auto'
          }}
          className="dialog-content"
        >
          {dialogContent}
        </section>
      </section>
    </section>
  );
};

export default Dialog;
