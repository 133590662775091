import NoDataIcon from 'assets/images/icons/no_data.svg';
import './index.css';

const NoData = ({ message = 'No data found' }) => {
  return (
    <section className="no-data">
      <img src={NoDataIcon} alt="No data found" />
      <p>{message}</p>
    </section>
  );
};

export default NoData;
