import { IDateDetails } from 'pages/schools/models/index.models';
import { useEffect, useState } from 'react';
import { studentsService } from 'services';

export interface IBehavioralNote {
  note: string;
  school_id: string;
  year: number;
  author: string;
  class_id: string;
  rating: number;
  student_id: string;
  admission_no: string;
  date_updated: IDateDetails;
  date_created: IDateDetails;
  id: string;
}

export const useBehavioral = (data: { student_id: string; class_id: string; year: number }) => {
  const [notes, setNotes] = useState<IBehavioralNote[]>([]);
  const [fetchingNotes, setFetchingNotes] = useState(true);

  useEffect(() => {
    studentsService.getBehavioral(data, notes => {
      setFetchingNotes(false);
      setNotes(notes);
    });
  }, [data]);

  return {
    notes,
    fetchingNotes
  };
};
