import { DownloadIcon, UploadIcon } from '@primer/octicons-react';
import { Button } from '@primer/react';
import { CoreContext } from 'contexts';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { coreService, feesService } from 'services';
import styled from 'styled-components';

const StyledFees = styled.section`
  line-height: 1.5;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin: 20px 0;
  padding: 20px;
  max-width: 1250px;

  .fees-title {
    color: var(--green);
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .fees {
    &-export {
      display: flex;
      align-items: flex-start;
      flex: 1;
      gap: 20px;

      &-btns {
        margin-top: 10px;
      }
    }

    &-description {
      font-size: 0.85rem;
      color: var(--text-color);
      margin-bottom: 10px;

      p {
        margin: 0;
      }
    }
  }

  form {
    border: 1px solid var(--border-color);
    border-radius: 15px;
    padding: 15px;
    padding: 20px;
    flex: 1;
    margin: 0;
  }

  .form-group-btns {
    margin: 0;
    padding: 0;
  }
`;

export const Fees = ({ school_id }: { school_id: string }) => {
  const { setShowAlert } = useContext(CoreContext);
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [firstUpload, setFirstUpload] = useState('on');
  const [query, setQuery] = useState('all');

  const handleBulkUpload = async (event: any) => {
    event.preventDefault();
    try {
      setUploading(true);
      const { file } = event.target;
      await coreService.uploadFiles([file.files[0]], `${school_id}`, `fees`, '', async res => {
        try {
          if (!res.uploading) {
            await feesService.uploadFees(
              {
                school_id,
                file_name: res.files[0].name ?? ''
              },
              data => {
                setUploading(false);
                setShowAlert({
                  message: data?.message ?? 'Fees uploaded successfully, processing...',
                  show: true,
                  type: 'success',
                  title: 'Success'
                });
                event.target.reset();
              }
            );
          }
        } catch (error: any) {
          setUploading(false);
          setShowAlert({
            message: error?.message ?? 'An error occurred',
            show: true,
            type: 'error',
            title: 'Error'
          });
        }
      });
    } catch (error: any) {
      setShowAlert({
        message: error?.message ?? 'An error occurred',
        show: true,
        type: 'error',
        title: 'Error'
      });
      setGeneratingTemplate(false);
    }
  };

  const handleExportTemplate = async (event: any) => {
    event.preventDefault();
    try {
      setGeneratingTemplate(true);
      await feesService.exportFeesTemplate(school_id, query, data => {
        coreService.generateFileFromData(
          data,
          `${school_id.split(' ').join('_')}-${new Date().getFullYear()}-fees-template`,
          'text/csv'
        );
        setGeneratingTemplate(false);
      });
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
      setGeneratingTemplate(false);
    }
  };

  return (
    <StyledFees>
      <div className="fees-title">Fees</div>
      <div className="fees-export">
        <form>
          <div
            className="
        fees-description"
          >
            <p>
              Please download the template below and populate students' fees data before uploading. Please <b>note</b>{' '}
              that;
            </p>{' '}
            <ol>
              <li>
                The file should be in{' '}
                <b>
                  <i>CSV</i> format.
                </b>
              </li>
              <li>
                Balance should be in this format:
                <b>
                  {' '}
                  <i>1000 or 10, no currency symbol or any other characters.</i>
                </b>
              </li>
              <li>
                The file should contain the following columns:{' '}
                <b>
                  {' '}
                  <i>student_id, admission_no, first_name, last_name, balance</i>
                </b>
              </li>
            </ol>
          </div>
          <div className="form-group">
            <label htmlFor="firstUpload" className="form-label">
              Is this the first upload?
            </label>
            <div className="form-group-radio">
              <div className="form-group-radio-item">
                <input
                  type="radio"
                  name="firstUpload"
                  id="yes"
                  required
                  checked={firstUpload === 'on'}
                  value="on"
                  onChange={e => setFirstUpload(e.target.value)}
                />
                <label htmlFor="yes">Yes</label>
              </div>
              <div className="form-group-radio-item">
                <input
                  type="radio"
                  name="firstUpload"
                  id="no"
                  required
                  value="off"
                  checked={firstUpload === 'off'}
                  onChange={e => setFirstUpload(e.target.value)}
                />
                <label htmlFor="no">No</label>
              </div>
            </div>
          </div>
          {firstUpload === 'off' ? (
            <div className="form-group">
              <label htmlFor="query" className="form-label">
                Student Query Type
              </label>
              <select
                name="query"
                id="query"
                className="form-input"
                required
                defaultValue={'all'}
                onChange={e => setQuery(e.target.value)}
              >
                {[
                  {
                    id: 'all',
                    name: 'All Students'
                  },
                  {
                    id: 'balance',
                    name: 'With Balance'
                  }
                ].map(subject => (
                  <option key={subject.id} value={subject.id}>
                    {subject.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <Button type="button" disabled={generatingTemplate} onClick={handleExportTemplate}>
            <DownloadIcon size={16} />
            <span
              style={{
                marginLeft: 5
              }}
            >
              {generatingTemplate ? 'Generating...' : 'Download Template'}
            </span>
          </Button>
        </form>
        <form onSubmit={handleBulkUpload}>
          <div
            className="
        fees-description"
          >
            Upload the populated template below to update students' fees data. Please ensure the file is in CSV format.
          </div>
          <div className="form-group">
            <label htmlFor="file" className="form-label">
              Upload Template
            </label>
            <input type="file" name="file" id="file" className="form-input" accept=".csv" required />
          </div>
          <div className="form-group-btns">
            <Button disabled={uploading} type="submit">
              <UploadIcon size={16} />
              <span
                style={{
                  marginLeft: 5
                }}
              >
                {uploading ? 'Please wait...' : 'Upload Fees'}
              </span>
            </Button>
          </div>
        </form>
      </div>
    </StyledFees>
  );
};
