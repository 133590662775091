import styled from 'styled-components';

const StyledFees = styled.div`
  .fees-title {
    font-size: 20px;
    font-weight: 600;
    color: #24292e;
  }

  .fees-container {
    padding: 20px 0;
    display: flex;
    gap: 20px;

    .left {
      flex: 1;
    }

    .right {
      flex: 1;
    }
  }
`;

export const Fees = () => {
  return (
    <StyledFees>
      <h2 className="fees-title">Fees</h2>
      <div className="fees-container">
        <div className="left">
          <p>Left</p>
        </div>
        <div className="right">
          <p>Right</p>
        </div>
      </div>
    </StyledFees>
  );
};
