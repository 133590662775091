import { CounterLabel, Button } from '@primer/react';
import { CoreContext } from 'contexts';
import { headers } from 'data';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { coreService, dbService } from 'services';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const StydledAccount = styled.div``;

export const Account = () => {
  const { setShowLineLoader, user, profile, setProfile, role } = useContext(CoreContext);
  const [editProfile, setEditProfile] = useState(false);
  const [phoneError, setPhoneError] = useState<string | null>(null);

  const handleProfileUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const form = event.currentTarget as HTMLFormElement;
      const formData = new FormData(form);
      const data = Object.fromEntries(formData.entries());
      if (profile?.id) {
        setShowLineLoader(true);
        await coreService.updateSchool(profile.id, data, res => {
          setProfile(res.data);
          setEditProfile(false);
          setShowLineLoader(false);
          toast.success(res.message);
        });
      }
    } catch (error) {
      setShowLineLoader(false);
      if (error instanceof Error) toast.error(error.message);
    }
  };

  return (
    <StydledAccount>
      <div className="manage-type">
        <div className="manage-type-item">
          <div className="manage-type-item-title">
            <CounterLabel>01</CounterLabel>
            <span>Account Details</span>
            {role === 'school' && (
              <Button onClick={() => setEditProfile(prev => !prev)} size="small">
                {editProfile ? 'Cancel Edit' : 'Edit Profile'}
              </Button>
            )}
          </div>
          <div className="manage-type-item-content">
            {role === 'school' && profile ? (
              <form className="manage-create-form" onSubmit={handleProfileUpdate}>
                <div className="form-group-flex">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      name="name"
                      id="name"
                      className="form-input"
                      placeholder="For e.g Recess School"
                      type="text"
                      disabled={!editProfile}
                      required={true}
                      defaultValue={profile?.name ?? ''}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="code" className="form-label">
                      Code
                    </label>
                    <input
                      name="code"
                      id="code"
                      className="form-input"
                      placeholder="For e.g KNEC001"
                      type="text"
                      disabled={!editProfile}
                      required={true}
                      defaultValue={profile?.code ?? ''}
                    />
                  </div>
                </div>
                <div className="form-group-flex">
                  <div className="form-group">
                    <label htmlFor="type" className="form-label">
                      Type
                    </label>
                    <select
                      name="type"
                      id="type"
                      disabled={!editProfile}
                      className="form-input"
                      required={true}
                      defaultValue={profile?.type ?? 'public'}
                    >
                      <option value="public">Public</option>
                      <option value="private">Private</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="level" className="form-label">
                      Level
                    </label>
                    <select
                      name="level"
                      id="level"
                      disabled={!editProfile}
                      className="form-input"
                      required={true}
                      defaultValue={profile?.level ?? 'primary'}
                    >
                      <option value="primary">Primary</option>
                      <option value="secondary">Secondary</option>
                      <option value="tertiary">Tertiary</option>
                    </select>
                  </div>
                </div>
                <div className="form-group-flex">
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      disabled={true}
                      className="form-input"
                      placeholder="For e.g"
                      type="email"
                      required={true}
                      defaultValue={user?.email ?? ''}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      name="phone_number"
                      id="phone"
                      className="form-input"
                      placeholder="For e.g +254 700 000 000"
                      type="tel"
                      disabled={!editProfile}
                      required={true}
                      onChange={event => {
                        const phone = event.target.value;
                        const checkRes = coreService.validatePhoneNumber(phone, 'KE');
                        setPhoneError(checkRes.error);
                      }}
                      defaultValue={profile?.phone_number ?? ''}
                    />
                    <div className="form-group-invalid">{phoneError}</div>
                  </div>
                </div>
                <div className="form-group-flex">
                  <div className="form-group">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <select
                      name="country"
                      id="country"
                      disabled={true}
                      className="form-input"
                      required={true}
                      defaultValue={profile?.country ?? ''}
                    >
                      <option value="KE">Kenya</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="county" className="form-label">
                      County
                    </label>
                    <select
                      name="county"
                      id="county"
                      className="form-input"
                      required={true}
                      disabled={!editProfile}
                      defaultValue={profile?.county ?? ''}
                    >
                      {headers.counties.map(county => (
                        <option key={county.code} value={county.name}>
                          {county.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>{' '}
                  <textarea
                    name="address"
                    id="address"
                    className="form-input"
                    placeholder="For e.g Nairobi, Kenya"
                    defaultValue={profile?.address ?? ''}
                    disabled={!editProfile}
                    required={true}
                  ></textarea>
                </div>
                <Button disabled={!editProfile || phoneError !== null} type="submit">
                  Update Profile
                </Button>
              </form>
            ) : (
              <div className="manage-user-blurb">You are not a school, you cannot edit your profile yet</div>
            )}
          </div>
        </div>
        <div className="manage-type-item">
          <div className="manage-type-item-title">
            <CounterLabel>02</CounterLabel>
            <span>Password & Security</span>
          </div>
          <div className="manage-type-item-content">
            <div className="manage-user-blurb">
              You can <b>ONLY</b> change your password at the moment.
            </div>
            <div className="manage-user-ctas">
              <Button
                onClick={async () => {
                  try {
                    const res = await Swal.fire({
                      title: 'Reset Password',
                      text: 'Are you sure you want to reset your password?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Yes',
                      cancelButtonText: 'No',
                      confirmButtonColor: '#1cbc96',
                      cancelButtonColor: '#6a737d'
                    });
                    if (user && user.email && res.isConfirmed) {
                      setShowLineLoader(true);
                      await dbService.sendPasswordResetEmail(dbService.auth, user.email);
                      toast.success('Password reset email sent, check your email for instructions');
                      setShowLineLoader(false);
                    } else {
                      setShowLineLoader(false);
                    }
                  } catch (error) {
                    if (error instanceof Error) toast.error(error.message);
                  }
                }}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StydledAccount>
  );
};
