import { ArrowLeftIcon } from '@primer/octicons-react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import userAvatar from '../../assets/images/user-avatar.png';
import { CoreContext } from '../../contexts';
import './index.css';
import { Avatar } from '@primer/react';

const Header = ({
  back
}: {
  back?: {
    label?: string;
    link: string;
  };
}) => {
  const { user, role, profile } = useContext(CoreContext);
  return (
    <header className="header">
      {back ? (
        <Link
          to={back.link}
          className="header-back"
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            gap: '0.4rem'
          }}
        >
          <ArrowLeftIcon size={24} />
          <span>Back</span>
        </Link>
      ) : null}
      <div className="header-user">
        <Avatar
          className="header-user-avatar"
          src={role === 'school' && profile?.photo_url ? profile?.photo_url : userAvatar}
          alt={role === 'school' && profile?.photo_url ? 'School logo' : 'User avatar'}
        />
        <span className="header-user-name">{user?.email || 'Loading...'}</span>
      </div>
    </header>
  );
};

export default Header;
